import React from "react"
import IonNavPage from "../components/IonNavPage"
import "../theme/styles.css"
import EditPanels from "../components/settings/EditPanels"
import Account from "../components/settings/Account"
import Devices from "../components/settings/Devices"
import { IonLabel } from "@ionic/react"

const Settings: React.FC = () => (
  <IonNavPage title="Settings">
    <div className="grid">
      <EditPanels />
      <Account />
      <Devices />
    </div>
    <div className="footer">
      <IonLabel color="medium">Version 1.0 (71)</IonLabel>
    </div>
  </IonNavPage>
)

export default Settings
