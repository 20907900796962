import firebase from "firebase"
import { Plugins, PushNotificationToken } from "@capacitor/core"
const { PushNotifications, Device } = Plugins

export const initializeFirebase = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyBdAm1MzLVTca5bEeznggMPeZmK9rlr0Pk",
    authDomain: "auth.sendy.app",
    databaseURL: "https://app-sendy.firebaseio.com",
    projectId: "app-sendy",
    storageBucket: "cdn.sendy.app",
    messagingSenderId: "120110644516",
    appId: "1:120110644516:web:cf76052fb9d26bbbd287b1",
    measurementId: "G-3PG10J773L",
  }
  firebase.initializeApp(firebaseConfig)
}

export const askForPermissionToReceiveNotifications = async (
  callback: Function
) => {
  const platform = (await Device.getInfo()).platform
  if (platform === "android") {
    const result = await PushNotifications.requestPermission()
    if (result.granted) PushNotifications.register()
    else {
      callback(null)
      return
    }
    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        callback(token.value)
      }
    )
    PushNotifications.addListener("registrationError", (error: any) => {
      console.error(error)
      callback(null)
    })
  } else if (platform === "electron") {
    // TODO: handle electron
  } else if (firebase.messaging.isSupported()) {
    try {
      const messaging = firebase.messaging()
      await Notification.requestPermission()
      const token = await messaging.getToken()
      callback(token)
    } catch (error) {
      if (error.code !== "messaging/permission-blocked") console.error(error)
      callback(null)
    }
  }
}
