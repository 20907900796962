import React from "react"
import { firestore } from "firebase"
import TextPanel from "../components/panels/TextPanel"
import FilesPanel from "../components/panels/FilesPanel"
import { userID } from "./FirebaseRefs"

export const makePanelComponent = (panel: PanelType, size: PanelSize) => {
  switch (panel.metadata.type) {
    case "text":
      return <TextPanel panel={panel as TextPanelType} size={size} />
    case "files":
      return <FilesPanel panel={panel as FilesPanelType} size={size} />
  }
}

export const createEmptyPanel = (type: PanelMetadataType, name: string) => {
  const basePanel = {
    metadata: {
      name: name,
      owner: userID(),
      type: type,
      shared: [userID()],
    },
  }
  switch (type) {
    case "text":
      return {
        ...basePanel,
        items: {},
      }
    case "files":
      return {
        ...basePanel,
        items: {},
      }
  }
}

export type PanelSize = "inline" | "fullScreen"

export type PanelType = {
  id: string
  metadata: PanelMetadata
}

export const panelTypes = ["files", "text"] as const

export type PanelMetadataType = typeof panelTypes[number]

export type PanelMetadata = {
  name: string
  owner: string
  type: PanelMetadataType
  shared: string[]
}

export interface FilesPanelType extends PanelType {
  items: { [id: string]: FilesPanelItem }
}

export type FilesPanelItem = {
  date: firestore.Timestamp
  name: string
  size: number
  status: number
  notify: string[]
}

export interface TextPanelType extends PanelType {
  items: { [id: string]: TextPanelItem }
}

export type TextPanelItem = {
  text: string
  title: string
  subtitle: string
  date: firestore.Timestamp
}
