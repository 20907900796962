import React, { useEffect, useContext } from "react"
import Panels from "../components/panels/Panels"
import { Route } from "react-router"
import Settings from "./Settings"
import { IonRouterOutlet } from "@ionic/react"
import { FirebaseContext, FirebaseContextType } from "../FirebaseContext"
import { askForPermissionToReceiveNotifications } from "../firebase"
import useIsSignedIn from "../hooks/useIsSignedIn"
import IonNavPage from "../components/IonNavPage"

const base = "/user"

const Main = () => {
  const isSignedIn = useIsSignedIn()
  const fbContext = useContext<FirebaseContextType>(FirebaseContext)

  useEffect(() => {
    if (isSignedIn === true)
      askForPermissionToReceiveNotifications(async (token: string | null) => {
        if (!token) return
        fbContext.currentDevice.setFcm(token)
        fbContext.currentDevice.getInfo().then((device) => {
          fbContext.updateDevice(device.id, {
            fcm: token,
            name: device.name,
          })
        })
      })
    // eslint-disable-next-line
  }, [isSignedIn])

  useEffect(() => {
    if (!fbContext.user) return
    const WINDOW = window as any
    WINDOW.$crisp.push([
      "set",
      "session:data",
      [
        [
          ["isSubscribed", fbContext.user?.isSubscribed() ?? false],
          [
            "nextBillDate",
            fbContext.user?.subscription?.nextBillDate?.toDate().toString() ??
              "NO BILL DATE",
          ],
          [
            "endDate",
            fbContext.user?.subscription?.endDate?.toDate().toString() ??
              "NO END DATE",
          ],
          [
            "subscriptionProvider",
            fbContext.user?.subscription?.provider ?? "NO PROVIDER",
          ],
        ],
      ],
    ])
  }, [fbContext.user])

  return (
    <>
      <IonRouterOutlet>
        <Route exact path={base}>
          <IonNavPage title="Sendy">
            <Panels />
          </IonNavPage>
        </Route>
        <Route path={`${base}/settings`}>
          <Settings />
        </Route>
      </IonRouterOutlet>
    </>
  )
}

export default Main
