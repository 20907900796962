import React from "react"
import { Panels } from "../../hooks/usePanelsData"
import { makePanelComponent } from "../../data/PanelTypes"
import "../../theme/styles.css"
import { IonPage, IonContent } from "@ionic/react"

type PanelContentProps = {
  panelIds: string[]
  panels: Panels
  selectedPanel: string
}

const PanelsContent = ({
  panelIds,
  panels,
  selectedPanel,
}: PanelContentProps) => (
  <IonPage id="panels">
    <IonContent>
      {selectedPanel === "all" ? (
        <div className="grid">
          {panelIds
            .map((id) => {
              const panel = panels[id]
              return panel !== undefined ? (
                <div key={`${id}-inline`}>
                  {makePanelComponent(panel, "inline")}
                </div>
              ) : undefined
            })
            .filter((item) => item !== undefined)}
        </div>
      ) : (
        <div style={{ height: "95%" }}>
          {makePanelComponent(panels[selectedPanel], "fullScreen")}
        </div>
      )}
    </IonContent>
  </IonPage>
)

export default PanelsContent
