import React, { useEffect, useState } from "react"
import { useLocation } from "react-router"
import "../theme/styles.css"
import SignIn from "../pages/SignIn"
import { isPlatform } from "@ionic/react"

type LandingFrameProps = {
  src: string
  title: string
}

const LandingFrame = ({ src, title }: LandingFrameProps) => {
  const { hash } = useLocation()
  const [showLogin, setShowLogin] = useState(false)
  const [redirectURL, setRedirectURL] = useState<string | undefined>(undefined)

  const frameHash = () => {
    switch (hash) {
      case "#pricing":
        return "#pricing-02-691251"
      case "#learn-more":
        return "#other-01-404091"
      default:
        return ""
    }
  }

  useEffect(() => {
    window.addEventListener("loginClicked", (e: any) => {
      setShowLogin(true)
      setRedirectURL(isPlatform("electron") ? `#/${e.redirect}` :`/${e.redirect}`)
    })
  }, [])

  useEffect(() => {
    document.title = title
    window.matchMedia("(prefers-color-scheme: dark)").addListener(() => {
      setTimeout(() => {
        document.body.classList.remove("dark")
      }, 100)
    })
    document.body.classList.remove("dark")
    return () => {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches)
        document.body.classList.add("dark")
      else document.body.classList.remove("dark")
    }
  }, [title])

  return (
    <>
      <SignIn
        isPopover={true}
        redirect={redirectURL}
        popoverProps={{
          isOpen: showLogin,
          cssClass: "login-popover",
          onDidDismiss: () => setShowLogin(false),
        }}
      />
      <iframe
        src={`${src}${frameHash()}`}
        title={title}
        frameBorder="0"
        style={{ width: "100%", height: "100%" }}
      />
    </>
  )
}

export default LandingFrame
