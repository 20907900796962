import { useState, useEffect, useRef } from "react"

const useDragEvent = () => {
  const elem = useRef<any>(null)
  const [counter, setCounter] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const curr = elem.current as HTMLElement
    const dragEnterListener = () => setCounter((prev) => prev + 1)
    const dragLeaveListener = () => setCounter((prev) => prev - 1)
    const dropListener = () => setCounter(0)
    curr.addEventListener("dragenter", dragEnterListener)
    curr.addEventListener("dragleave", dragLeaveListener)
    curr.addEventListener("drop", dropListener)
    return () => {
      curr.removeEventListener("dragenter", dragEnterListener)
      curr.removeEventListener("dragleave", dragLeaveListener)
      curr.removeEventListener("drop", dropListener)
    }
  }, [])

  useEffect(() => {
    setIsDragging(counter !== 0)
  }, [counter])

  return { elem, isDragging }
}

export default useDragEvent
