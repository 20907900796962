import React, { useState } from "react"
import { PanelMetadata, PanelSize } from "../../../data/PanelTypes"
import { firestore } from "firebase"
import { ellipsisVertical } from "ionicons/icons"
import "../../../theme/styles.css"
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonButton,
  IonIcon,
} from "@ionic/react"
import Item from "../../Item"
import ModalPopover from "../../ModalPopover"
import useDragEvent from "../../../hooks/useDragEvent"
import "../../../theme/styles.css"

export type ItemsPanelItem = {
  id: string
  date: firestore.Timestamp
  title: string
  subtitle: string
}

type ItemsPanelProps = {
  metadata: PanelMetadata
  items: ItemsPanelItem[]
  actions: any
  size: PanelSize
}

const ItemsPanel = ({ items, metadata, actions, size }: ItemsPanelProps) => {
  const [showAddPopover, setShowAddPopover] = useState(false)
  const [showMenuState, setShowMenuState] = useState<any>(undefined)
  const { elem, isDragging } = useDragEvent()

  return (
    <IonCard
      ref={elem}
      style={
        size === "inline" ? { padding: -5 } : { padding: -5, height: "100%" }
      }
      className={
        isDragging && metadata.type === "files"
          ? "drop-zone-active"
          : "drop-zone-notactive"
      }
    >
      <IonCardHeader>
        <div className="row" style={{ height: 32 }}>
          <IonCardTitle className="card-title">{metadata.name}</IonCardTitle>
          <IonButton
            fill="outline"
            size="small"
            onClick={(e) => {
              e.persist()
              setShowAddPopover(true)
            }}
          >
            {metadata.type === "files" ? "Upload" : "Add"}
          </IonButton>
          <ModalPopover
            isOpen={showAddPopover}
            cssClass="add-popover"
            title={metadata.type === "files" ? "Upload File" : "Add Item"}
            onDidDismiss={() => setShowAddPopover(false)}
          >
            {actions.addDialog(() => setShowAddPopover(false))}
          </ModalPopover>
        </div>
      </IonCardHeader>
      <IonCardContent
        style={
          size === "inline"
            ? { height: 315, overflowY: "scroll" }
            : { height: "100%", overflowY: "scroll" }
        }
      >
        <div className="grid-small">
          {items
            .sort(
              (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
            )
            .map((item) => (
              <Item
                key={item.id}
                button
                onClick={() => actions.onClick && actions.onClick(item)}
              >
                <IonLabel>
                  {item.title}
                  <p>{item.subtitle === "" ? "⠀" : item.subtitle}</p>
                </IonLabel>
                {actions.menu && (
                  <IonButton
                    fill="clear"
                    shape="round"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.persist()
                      setShowMenuState({ event: e, item: item })
                    }}
                  >
                    <IonIcon icon={ellipsisVertical} />
                  </IonButton>
                )}
              </Item>
            ))}
        </div>
        {showMenuState &&
          actions.menu(
            showMenuState.item,
            !!showMenuState,
            showMenuState.event,
            () => setShowMenuState(undefined)
          )}
      </IonCardContent>
    </IonCard>
  )
}

export default ItemsPanel
