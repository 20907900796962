import React from "react"
import ReactDOM from "react-dom"
import { Plugins, StatusBarStyle, SplashScreen } from "@capacitor/core"
import { Purchases } from "@ionic-native/purchases"
import App from "./App"
import { initializeFirebase } from "./firebase"
import { auth } from "firebase"

ReactDOM.render(<App />, document.getElementById("root"))

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js")
}

initializeFirebase()

Plugins.Device.getInfo().then((info) => {
  if (info.platform === "web") preparePaddle()
  else if (info.platform === "android") {
    prepareRevenueCat()
    setAndroidStatusBarColor()
  }
})

function setAndroidStatusBarColor() {
  const theme = () =>
    getComputedStyle(document.body)
      .getPropertyValue("--ion-background-color")
      .replace(/\s/g, "") || "#ffffff"
  const changeTheme = () => {
    Plugins.StatusBar.setBackgroundColor({ color: theme() })
    const dark =
      window.matchMedia("(prefers-color-scheme: dark)").matches ||
      window.navigator.userAgent.includes("AndroidDarkMode")
    Plugins.StatusBar.setStyle({
      style: dark ? StatusBarStyle.Dark : StatusBarStyle.Light,
    })
    SplashScreen.hide()
  }
  changeTheme()
  window.matchMedia("(prefers-color-scheme: dark)").addListener(() => {
    changeTheme()
  })
}

// Prepare Paddle for the web
function preparePaddle() {
  const paddleSrc = document.createElement("script")
  paddleSrc.setAttribute("src", "https://cdn.paddle.com/paddle/paddle.js")
  document.body.appendChild(paddleSrc)
  setTimeout(() => {
    const paddleSetup = document.createElement("script")
    paddleSetup.setAttribute("type", "text/javascript")
    const setupSrc = document.createTextNode("Paddle.Setup({ vendor: 111904 })")
    paddleSetup.appendChild(setupSrc)
    document.body.appendChild(paddleSetup)
  }, 2000)
  const html = document.getElementsByTagName("html")[0]
  const observer = new MutationObserver((event) => {
    const classes = html.classList
    for (let i = 0; i < classes.length; i++) {
      if (classes[i].startsWith("mvc")) classes.remove(classes[i])
    }
  })
  observer.observe(html, {
    attributes: true,
    attributeFilter: ["class"],
    childList: false,
    characterData: false,
  })
}

// Prepare RevenueCat for android
async function prepareRevenueCat() {
  Purchases.setup("eIPqMxTjquUFokNPzdAKSrddJPVUFCkg")
  auth().onAuthStateChanged((user) => {
    if (user?.uid) {
      Purchases.identify(user.uid)
        .then((info) => console.log("Linked user to RevenueCat"))
        .catch((error) => console.log("Didn't link user to RevenueCat", error))
    } else {
      Purchases.reset()
        .then((info) => console.log("User signed out from RevenueCat"))
        .catch((error) =>
          console.log("Couldn't sign out from RevenueCat", error)
        )
    }
  })
}
