import React, {
  useState,
  FormEvent,
  useRef,
  useEffect,
  useContext,
} from "react"
import { TextPanelItem, TextPanelType } from "../../../data/PanelTypes"
import {
  IonInput,
  IonTextarea,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react"
import { panelsCol, currentTimestamp } from "../../../data/FirebaseRefs"
import randomString from "../../../data/randomString"
import showToast from "../../showToast"
import Item from "../../Item"
import "../../../theme/styles.css"
import { functions } from "firebase"
import { FirebaseContextType, FirebaseContext } from "../../../FirebaseContext"

type AddToTextPanelProps = {
  panel: TextPanelType
  id?: string
  item?: TextPanelItem
  dismiss: Function
}

let isLoadingLink = false

const AddToTextPanel = ({ panel, id, item, dismiss }: AddToTextPanelProps) => {
  const { user } = useContext<FirebaseContextType>(FirebaseContext)
  const [mode, setMode] = useState<"link" | "text">(
    isValidURL(item?.title ?? "") && item?.subtitle === "Webpage"
      ? "link"
      : "text"
  )
  const [text, setText] = useState(item?.text ?? "")
  const [title, setTitle] = useState(item?.title ?? "")
  const [subtitle, setSubtitle] = useState(item?.subtitle ?? "")
  const textArea = useRef<any>(null)

  useEffect(() => {
    if (mode === "link") setText(title)
    setTimeout(() => textArea.current.setFocus(), 100)
    // eslint-disable-next-line
  }, [])

  function isValidURL(text: string) {
    try {
      new URL(text)
    } catch (_) {
      return false
    }
    return true
  }

  const updateItem = async (e: FormEvent) => {
    e.preventDefault()
    let textToSave = text
    let titleToSave = title
    let subtitleToSave = subtitle
    if (user?.isSubscribed() ?? false) {
      if (Object.keys(panel.items).length >= 100) {
        showToast(
          "Sendy+ users can add up to 100 items to each Panel.",
          "warning"
        )
        return
      }
    } else {
      if (Object.keys(panel.items).length >= 3) {
        showToast(
          "Sendy Free users can add up to 3 items to each Panel. Upgrade to Sendy+ to upload up to 100 items.",
          "warning"
        )
        return
      }
    }
    if (text === "") {
      showToast("You didn't type text.", "error")
      return
    }
    if (mode === "link") {
      if (isLoadingLink) return
      if (!isValidURL(textToSave)) {
        showToast("This URL isn't valid.", "error")
        return
      }
      const url = textToSave
      isLoadingLink = true
      const res = await functions().httpsCallable("getURLMetadata")({
        url: url,
      })
      isLoadingLink = false
      if (res.data !== "ERROR") {
        textToSave = res.data.title ?? "Couldn't Load Title"
        titleToSave = res.data.url ?? textToSave
        subtitleToSave = "Webpage"
      } else {
        showToast("Couldn't get URL metadata.", "error")
        return
      }
    }
    const length = (textToSave + titleToSave + subtitleToSave).length
    if (length > 5000) {
      showToast("Max item size is 5000 characters.", "error")
      return
    }
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${id ?? randomString(20)}`]: {
          text: textToSave,
          title: titleToSave,
          subtitle: subtitleToSave,
          date: item?.date ?? currentTimestamp(),
        },
      })
      .then(() =>
        showToast(
          `"${titleToSave !== "" ? titleToSave : textToSave}" ${
            item ? "updated" : "added"
          }`
        )
      )
      .catch(() =>
        showToast(
          `Couldn't ${item ? "update" : "add"} "${
            title !== "" ? title : text
          }"`,
          "error"
        )
      )
    dismiss()
  }

  return (
    <>
      <IonSegment
        className="text-segment"
        value={mode}
        onIonChange={(e) => {
          setMode((e.detail.value ?? "text") === "text" ? "text" : "link")
          setTimeout(() => textArea.current.setFocus(), 10)
        }}
      >
        <IonSegmentButton value="text">
          <IonLabel>Text</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="link">
          <IonLabel>Link</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div style={{ padding: 8 }}>
        <form onSubmit={updateItem}>
          <Item className="item-radius">
            <IonTextarea
              ref={textArea}
              rows={5}
              placeholder={mode === "text" ? "Text" : "URL"}
              value={text}
              onIonChange={(e) => setText(e.detail.value ?? "")}
            />
          </Item>
          {mode === "text" && (
            <>
              <Item className="item-radius" style={{ marginTop: 8 }}>
                <IonInput
                  placeholder="Title"
                  value={title}
                  onIonChange={(e) => setTitle(e.detail.value ?? "")}
                />
              </Item>
              <Item className="item-radius" style={{ marginTop: 8 }}>
                <IonInput
                  placeholder="Subtitle"
                  value={subtitle}
                  onIonChange={(e) => setSubtitle(e.detail.value ?? "")}
                />
              </Item>
            </>
          )}
          <IonButton
            type="submit"
            expand="block"
            style={{ marginTop: 8, height: 45 }}
          >
            {item ? "Update" : "Add"}
          </IonButton>
        </form>
      </div>
    </>
  )
}

export default AddToTextPanel
