import React, { useState, useEffect } from "react"
import { IonButton } from "@ionic/react"

const ManagePaddleSubscription = ({
  subscriptionHandler,
  subscriptionData,
}: any) => {
  const [platform, setPlatform] = useState("")

  useEffect(() => {
    subscriptionHandler.platform().then((plat: string) => setPlatform(plat))
  }, [subscriptionHandler])

  switch (platform) {
    default:
      return (
        <div>
          <IonButton
            fill="outline"
            expand="block"
            style={{ height: 50 }}
            onClick={() =>
              subscriptionHandler.updatePaddleSubscription(
                subscriptionData.updateURL
              )
            }
          >
            Update Payment Method
          </IonButton>
          <IonButton
            fill="outline"
            expand="block"
            style={{ height: 50 }}
            onClick={() =>
              subscriptionHandler.updatePaddleSubscription(
                subscriptionData.cancelURL
              )
            }
          >
            Cancel Subscription
          </IonButton>
        </div>
      )
  }
}

export default ManagePaddleSubscription
