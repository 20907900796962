import React, { useState, useEffect } from "react"
import { FilesPanelType } from "../../../data/PanelTypes"
import { IonLabel, IonButton, IonProgressBar } from "@ionic/react"
import randomString from "../../../data/randomString"
import {
  storageRef,
  panelsCol,
  currentTimestamp,
} from "../../../data/FirebaseRefs"
import showToast from "../../showToast"
import { storage, firestore } from "firebase"

type UploadDialogProps = {
  panel: FilesPanelType
  file: File
  notify: any
  dismiss: Function
}

let progressOnCancel = 0
let uploadTask: storage.UploadTask | null = null

const UploadDialog = ({ panel, file, notify, dismiss }: UploadDialogProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const id = randomString(20)
    progressOnCancel = 0
    uploadTask = null
    addFile(id)
    return () => {
      if (progressOnCancel !== 1) {
        uploadTask?.cancel()
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    progressOnCancel = progress
  }, [progress])

  function addFile(id: string) {
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${id}`]: {
          date: currentTimestamp(),
          name: file.name,
          size: file.size,
          status: 0,
          notify: notify.map((device: any) => device.fcm),
        },
      })
      .then(() => uploadFile(id))
      .catch(() => {
        showToast(`Couldn't upload ${file.name}`, "error")
        dismiss()
      })
  }

  function uploadFile(id: string) {
    const ref = storageRef().child(panel.id).child(id).child(file.name)
    uploadTask = ref.put(file)
    uploadTask.then(() => finishUpload(id))
    uploadTask.catch(() => {
      if (uploadTask!.snapshot.state === storage.TaskState.CANCELED) {
        showToast(`${file.name} upload canceled`)
      }
      if (uploadTask!.snapshot.state === storage.TaskState.ERROR) {
        showToast(`Couldn't upload ${file.name}`, "error")
      }
      removeDbEntryOnError(id)
      dismiss()
    })
    uploadTask.on(storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
      if (snapshot.state === storage.TaskState.PAUSED)
        showToast("Upload is paused.")
    })
  }

  function removeDbEntryOnError(id: string) {
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${id}`]: firestore.FieldValue.delete(),
      })
  }

  function finishUpload(id: string) {
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${id}`]: {
          date: currentTimestamp(),
          name: file.name,
          size: file.size,
          status: 1,
          notify: notify.map((device: any) => device.fcm),
        },
      })
      .then(() => {
        showToast(`${file.name} uploaded`)
        dismiss()
      })
      .catch(() => {
        showToast(`Couldn't upload ${file.name}`, "error")
        dismiss()
      })
  }

  return (
    <div>
      <IonLabel style={{ fontSize: 18, fontWeight: "500" }}>
        Uploading {file.name}...
      </IonLabel>
      <br />
      <br />
      <IonProgressBar value={progress} />
      <IonLabel color="medium">{Math.round(progress * 100)}%</IonLabel>
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IonButton
          fill="clear"
          onClick={() => {
            dismiss()
          }}
        >
          Cancel Upload
        </IonButton>
      </div>
    </div>
  )
}

export default UploadDialog
