import React from "react"
import { IonItem } from "@ionic/react"

const Item = (props: any) => (
  <IonItem detail={false} {...props}>
    {props.children}
  </IonItem>
)

export default Item
