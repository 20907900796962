import { firestore } from "firebase"
import Device from "./Device"
import randomString from "./randomString"

export type Subscription = {
  status: "active" | "trialing" | "past_due" | "paused" | "deleted"
  nextBillDate?: firestore.Timestamp
  plan: "monthly" | "yearly"
  provider: "paddle" | "apple" | "google"
  endDate?: firestore.Timestamp
  updateURL?: string
  cancelURL?: string
}

export type ServerUser = {
  subscription?: Subscription
  devices: any
}

export type UserType = {
  subscription?: Subscription
  devices: () => Device[]
  presentedDevices: () => Device[]
  notificationDevices: (currentDeviceId: string) => Device[]
  deviceForId: (id: string) => Device
  isSubscribed: () => boolean
}

const User = ({ subscription, devices: devicesFromServer }: ServerUser) => {
  /** All of the user devices. */
  const devices = () =>
    Object.keys(devicesFromServer).map((key) => {
      const device = devicesFromServer[key]
      const id = key
      const name = device["name"] ?? ""
      const fcm = device["fcm"] ?? ""
      return { id, name, fcm } as Device
    })

  /** Devices that the user connected to its account. */
  const presentedDevices = () =>
    devices()
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .filter((device) => device.fcm !== "deleted")

  /** Devices that the user can interact with currently. */
  const notificationDevices = (currentDeviceId: string) => {
    return presentedDevices().filter(
      (device) =>
        (device.id !== currentDeviceId ?? randomString(20)) && device.fcm !== ""
    )
  }

  /**
  Get `Device` from id.
  @param id: Device id
  */
  const deviceForId = (id: string) => {
    const device = devices().find((device) => device.id === id)
    if (device) return device
    else if (id === "alldevices") {
      return {
        name: "All Devices",
        id: "alldevices",
        fcm: "alldevices",
      } as Device
    } else {
      return { name: "Deleted Device", id: "deleted", fcm: "deleted" } as Device
    }
  }

  /** Checks if the user is subscribed. */
  const isSubscribed = () => {
    if (!subscription) return false
    if (!subscription.endDate) return true
    return (
      subscription.endDate.toMillis() >
      firestore.Timestamp.fromDate(new Date()).toMillis()
    )
  }

  return {
    subscription,
    devices,
    presentedDevices,
    notificationDevices,
    deviceForId,
    isSubscribed,
  }
}

export default User
