type ToastType = "info" | "error" | "warning" | "success"

const removeTags = (s: string) =>
  s.replace(/</gi, "&lt;").replace(/>/gi, "&gt;")

const showToast = (text: string, type?: ToastType) => {
  const toastText = removeTags(text)
  // @ts-ignore
  type ? window.mdtoast(toastText, { type }) : window.mdtoast(toastText)
}

export default showToast
