import React from "react"
import { Panels } from "../../hooks/usePanelsData"
import "../../theme/styles.css"
import { IonMenu, IonContent, IonList, IonItem, IonButton } from "@ionic/react"

type PanelsMenuProps = {
  panelIds: string[]
  panels: Panels
  selectedPanel: string
  setSelectedPanel: Function
}

const PanelsMenu = ({
  panelIds,
  panels,
  selectedPanel,
  setSelectedPanel,
}: PanelsMenuProps) => {
  return (
    <IonMenu side="start" contentId="panels">
      <IonContent>
        <IonList style={{ backgroundColor: "transparent" }}>
          <IonItem
            button
            key="all"
            onClick={() => setSelectedPanel("all")}
            color={selectedPanel === "all" ? "light" : undefined}
          >
            <IonButton
              style={{ pointerEvents: "none" }}
              fill="clear"
              size="default"
              expand="full"
            >
              All Panels
            </IonButton>
          </IonItem>
          {panelIds
            ?.map((id) => {
              if (panels === null) return undefined
              const panel = panels![id]
              return panel !== undefined ? (
                <IonItem
                  button
                  key={`${id}-full`}
                  onClick={() => setSelectedPanel(id)}
                  color={selectedPanel === id ? "light" : undefined}
                >
                  <IonButton
                    style={{ pointerEvents: "none" }}
                    fill="clear"
                    size="default"
                    expand="full"
                  >
                    {panel.metadata.name}
                  </IonButton>
                </IonItem>
              ) : undefined
            })
            .filter((item) => item !== undefined)}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default PanelsMenu
