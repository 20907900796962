import React, { useState, useRef, useEffect, FormEvent } from "react"
import ModalPopover from "./ModalPopover"
import Item from "./Item"
import { IonInput, IonButton } from "@ionic/react"
import showToast from "./showToast"

type InputDialogProps = {
  isOpen: boolean
  title: string
  placeholder: string
  actionText: string
  dismissed: Function
  defaultValue?: string
  canBeEmpty?: boolean
  type?:
    | "number"
    | "time"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "search"
    | "date"
    | "password"
}

const InputDialog = (props: InputDialogProps) => {
  const [text, setText] = useState(props.defaultValue ?? "")
  const input = useRef<any>(null)

  useEffect(() => {
    setTimeout(() => input.current.setFocus(), 100)
  }, [])

  const finishEditText = (e: FormEvent) => {
    e.preventDefault()
    if (text === "" && !props.canBeEmpty) {
      showToast("You didn't type anything.", "error")
      return
    }
    const newText = text
    setText("")
    props.dismissed(newText)
  }

  return (
    <ModalPopover
      isOpen={props.isOpen}
      cssClass="add-popover"
      title={props.title}
      onDidDismiss={() => props.dismissed()}
    >
      <div style={{ padding: 8 }}>
        <form onSubmit={finishEditText}>
          <Item className="item-radius" style={{ marginTop: 8 }}>
            <IonInput
              ref={input}
              placeholder={props.placeholder}
              type={props.type ?? "text"}
              value={text}
              onIonChange={(e) => setText(e.detail.value ?? "")}
            />
          </Item>
          <IonButton
            type="submit"
            expand="block"
            style={{ marginTop: 8, height: 45 }}
          >
            {props.actionText}
          </IonButton>
        </form>
      </div>
    </ModalPopover>
  )
}

export default InputDialog
