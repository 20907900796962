import React, {
  useRef,
  FormEvent,
  useState,
  useContext,
  useEffect,
} from "react"
import { IonButton, IonChip, IonLabel, IonList, IonIcon } from "@ionic/react"
import { FilesPanelType } from "../../../data/PanelTypes"
import { FirebaseContext, FirebaseContextType } from "../../../FirebaseContext"
import Device from "../../../data/Device"
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons"
import UploadDialog from "./UploadDialog"
import showToast from "../../showToast"
import useDragEvent from "../../../hooks/useDragEvent"
import "../../../theme/styles.css"

type AddToFilesPanelProps = {
  panel: FilesPanelType
  dismiss: Function
  formatBytes: Function
  withFile?: File
}

const AddToFilesPanel = ({
  panel,
  dismiss,
  formatBytes,
  withFile = undefined,
}: AddToFilesPanelProps) => {
  const fileSelector = useRef<any>(null)
  const { elem, isDragging } = useDragEvent()
  const [file, setFile] = useState<File | undefined>(withFile)
  const fbContext = useContext<FirebaseContextType>(FirebaseContext)
  const [notifyDevices, setNotifyDevices] = useState<any>([])
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (fbContext.user && fbContext.user.notificationDevices)
      notifyDevicesFunc().then((devices) => setNotifyDevices(devices))
    // eslint-disable-next-line
  }, [fbContext.user])

  const uploadFile = (e: FormEvent) => {
    e.preventDefault()
    if (!file) {
      showToast("You didn't select a file.", "error")
      return
    }
    if (fbContext.user?.isSubscribed() ?? false) {
      if (Object.keys(panel.items).length >= 100) {
        showToast(
          "Sendy+ users can add up to 100 items to each Panel.",
          "warning"
        )
        return
      }
      // Max 1GB
      if (file.size > 1073741824) {
        showToast("Sendy+ users have a limit of 1GB per file.", "warning")
        return
      }
    } else {
      if (Object.keys(panel.items).length >= 3) {
        showToast(
          "Sendy Free users can add up to 3 items to each Panel. Upgrade to Sendy+ to upload up to 100 items.",
          "warning"
        )
        return
      }
      // Max 10MB
      if (file.size > 10485760) {
        showToast(
          "Sendy Free users have an upload limit of 10MB per file. Upgrade to Sendy+ to increase this limit to 1GB.",
          "warning"
        )
        return
      }
    }
    setIsUploading(true)
  }

  const selectFile = () => {
    if (!file) fileSelector.current.click()
    else {
      fileSelector.current.value = ""
      setFile(undefined)
    }
  }

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setFile(event.dataTransfer.files[0])
  }

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  async function notifyDevicesFunc() {
    const currentDeviceId = await fbContext.currentDevice.id()
    return fbContext
      .user!.notificationDevices(currentDeviceId)
      .map((device: Device) => ({
        id: device.id,
        fcm: device.fcm,
        name: device.name,
        checked: false,
      }))
  }

  const toggleDeviceNotify = (id: string) => {
    const newNotifyDevices = notifyDevices.map((device: any) => {
      if (device.id !== id) return device
      else return { ...device, checked: !device.checked }
    })
    setNotifyDevices(newNotifyDevices)
  }

  return (
    <div
      ref={elem}
      className={isDragging ? "drop-zone-active" : "drop-zone-notactive"}
      style={{ padding: 3 }}
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
    >
      {isUploading ? (
        <UploadDialog
          panel={panel}
          // @ts-ignore
          file={file}
          dismiss={dismiss}
          notify={notifyDevices.filter((device: any) => device.checked)}
        />
      ) : (
        <form onSubmit={uploadFile}>
          <IonButton
            fill="outline"
            expand="block"
            style={{ height: 45 }}
            onClick={selectFile}
          >
            {!file ? "Select File" : "Deselect File"}
          </IonButton>
          {file && (
            <IonLabel style={{ fontSize: 18, paddingTop: 8 }}>
              {file.name}
              <br />
              {formatBytes(file.size)}
              <br />
            </IonLabel>
          )}
          <br />
          {notifyDevices.length > 0 && (
            <>
              <IonLabel style={{ fontSize: 16, fontWeight: "500" }}>
                Notify...
              </IonLabel>
              <IonList className="background-default">
                {notifyDevices.map((device: any) => (
                  <IonChip
                    outline
                    key={device.id}
                    color={device.checked ? "primary" : "medium"}
                    onClick={() => toggleDeviceNotify(device.id)}
                  >
                    <IonIcon
                      icon={
                        device.checked ? checkmarkCircleOutline : ellipseOutline
                      }
                    />
                    <IonLabel>{device.name}</IonLabel>
                  </IonChip>
                ))}
              </IonList>
              <IonLabel color="medium" style={{ fontSize: 14 }}>
                Devices you select will get a notification when the file is
                uploaded.
              </IonLabel>
            </>
          )}
          <IonButton
            style={{ marginTop: 8, height: 45 }}
            expand="block"
            type="submit"
          >
            Upload File
          </IonButton>
        </form>
      )}
      <input
        ref={fileSelector}
        type="file"
        name="name"
        style={{ display: "none" }}
        onChange={() => setFile(fileSelector.current.files.item(0))}
      />
    </div>
  )
}

export default AddToFilesPanel
