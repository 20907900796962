import React from "react"
import {
  IonButton,
  IonButtons,
  IonIcon,
  useIonRouter,
  isPlatform,
} from "@ionic/react"
import { auth } from "firebase"
import { useRouteMatch, useHistory } from "react-router-dom"
import { cogOutline, settingsOutline, personCircle } from "ionicons/icons"
import useIsSignedIn from "../hooks/useIsSignedIn"
import useScreenSize from "../hooks/useScreenSize"
import useCurrentDevice from "../hooks/useCurrentDevice"

const SendyToolbarButtons = () => {
  const currentDevice = useCurrentDevice()

  const isPWA = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    document.referrer.includes("android-app://") ||
    currentDevice.platform !== "web" ||
    isPlatform("electron")

  const { path } = useRouteMatch()
  const { push } = useIonRouter()
  const history = useHistory()
  const isSignedIn = useIsSignedIn()
  const { width } = useScreenSize()

  const cancel = () => {
    if (history.length > 2 || isPWA()) history.goBack()
    else push("/")
  }

  const endButton = () => {
    switch (path) {
      case "/user":
        return (
          <IonButton routerLink="/user/settings" color="primary">
            <IonIcon ios={cogOutline} md={settingsOutline} />
          </IonButton>
        )
      case "/login":
        return !isPWA() ? (
          <IonButton
            onClick={() => window.location.replace("/")}
            color="primary"
          >
            Cancel
          </IonButton>
        ) : (
          <div></div>
        )
      case "/user/settings":
        return (
          <IonButton onClick={cancel} color="primary">
            Cancel
          </IonButton>
        )
    }
    if (isSignedIn)
      return (
        <IonButton routerLink="/user" color="primary">
          <IonIcon icon={personCircle} style={{ marginRight: 3 }} />
          {auth().currentUser?.displayName ??
            (width >= 640
              ? auth().currentUser?.email ?? "Open App"
              : "Open App")}
        </IonButton>
      )
    else
      return (
        <IonButton routerLink="/login" color="primary">
          Sign In
        </IonButton>
      )
  }

  return (
    <IonButtons slot="end" style={{ "-webkit-app-region": "no-drag" }}>
      {endButton()}
    </IonButtons>
  )
}

export default SendyToolbarButtons
