import React, { useState, useEffect } from "react"
import { IonButton, IonSpinner, IonAlert } from "@ionic/react"

const BuySubscription = ({ subscriptionHandler, setShowConfetti }: any) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [loadingPurchasePageMonthly, setLoadingPurchasePageMonthly] = useState(
    false
  )
  const [loadingPurchasePageYearly, setLoadingPurchasePageYearly] = useState(
    false
  )

  useEffect(() => {
    return () => {
      setShowSuccessAlert(false)
      setShowConfetti(true)
    }
  }, [setShowConfetti])

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <IonButton
          fill="outline"
          expand="block"
          disabled={loadingPurchasePageMonthly || loadingPurchasePageYearly}
          style={{ height: 50 }}
          onClick={() => {
            setLoadingPurchasePageMonthly(true)
            subscriptionHandler.buyMonthlyPlan(
              () => setShowSuccessAlert(true),
              () => setLoadingPurchasePageMonthly(false)
            )
          }}
        >
          {!loadingPurchasePageMonthly && subscriptionHandler.monthlyPrice ? (
            `${subscriptionHandler.monthlyPrice} Monthly`
          ) : (
            <IonSpinner />
          )}
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          disabled={loadingPurchasePageMonthly || loadingPurchasePageYearly}
          style={{ height: 50 }}
          onClick={() => {
            setLoadingPurchasePageYearly(true)
            subscriptionHandler.buyYearlyPlan(
              () => setShowSuccessAlert(true),
              () => setLoadingPurchasePageYearly(false)
            )
          }}
        >
          {!loadingPurchasePageYearly && subscriptionHandler.yearlyPrice ? (
            `${subscriptionHandler.yearlyPrice} Yearly`
          ) : (
            <IonSpinner />
          )}
        </IonButton>
      </div>
      <IonAlert
        isOpen={showSuccessAlert}
        header="Thank you for joining Sendy+ ❤️"
        message="You purchase is processing, please wait a few seconds..."
        backdropDismiss={false}
        buttons={[]}
      />
    </>
  )
}

export default BuySubscription
