import React, { useContext, useState, useEffect } from "react"
import "../../theme/styles.css"
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonAlert,
  IonButton,
  IonList,
  IonSpinner,
  IonIcon,
} from "@ionic/react"
import { FirebaseContext, FirebaseContextType } from "../../FirebaseContext"
import Item from "../Item"
import showToast from "../showToast"
import Device from "../../data/Device"
import { trashOutline } from "ionicons/icons"
import InputDialog from "../InputDialog"

type CurrentDeviceInfoType = {
  id: string
  name: string
  fcm: string
}

const Devices: React.FC = () => {
  const fbContext = useContext<FirebaseContextType>(FirebaseContext)
  const [modal, setModal] = useState<any>(undefined)
  const [alert, setAlert] = useState<any>(undefined)
  const [devices, setDevices] = useState<Device[] | undefined>(undefined)
  const [
    currentDeviceInfo,
    setCurrentDeviceInfo,
  ] = useState<CurrentDeviceInfoType | null>(null)

  useEffect(() => {
    if (fbContext.user) {
      setDevices(fbContext.user.presentedDevices())
      fbContext.currentDevice
        .getInfo()
        .then((info) => setCurrentDeviceInfo(info))
    }
    // eslint-disable-next-line
  }, [fbContext.user])

  const deleteDevice = (device: Device) => {
    fbContext
      .updateDevice(device.id, {
        name: "Deleted Device",
        fcm: "deleted",
      })
      .then(() => showToast(`Deleted ${device.name} successfully.`))
      .catch((error: any) => showToast(error, "error"))
  }

  const showRenameDevicePopover = (device: Device) => {
    const finishEditName = (text?: string) => {
      setModal(undefined)
      if (!text) return
      fbContext.currentDevice.id().then((id) => {
        if (device.id === id) fbContext.currentDevice.setName(text)
      })
      fbContext
        .updateDevice(device.id, {
          name: text,
          fcm: device.fcm,
        })
        .then(() => showToast(`Renamed ${text} successfully.`))
        .catch((error: any) => showToast(error, "error"))
    }
    setModal({
      title: "Rename Device",
      placeholder: "New Name",
      actionText: "Rename",
      dismissed: finishEditName,
    })
  }

  const removeTags = (s: string) =>
    s.replace(/</gi, "&lt;").replace(/>/gi, "&gt;")

  const content = (
    <>
      {devices &&
        currentDeviceInfo &&
        devices.map((device: Device) => (
          <Item
            button
            key={device.id}
            onClick={() => showRenameDevicePopover(device)}
          >
            <IonLabel>
              {device.name}
              {device.id === currentDeviceInfo.id && <p>This Device</p>}
            </IonLabel>
            <IonButton
              fill="clear"
              shape="round"
              className="destructive"
              onClick={(e) => {
                e.stopPropagation()
                e.persist()
                device.id !== currentDeviceInfo.id
                  ? setAlert({
                      title: `Delete ${device.name}?`,
                      message: `Confirm deletion of ${removeTags(
                        device.name
                      )}.`,
                      device: device,
                    })
                  : setAlert({
                      title: `Delete ${device.name} and Sign Out?`,
                      message: "Removing this device will also sign you out.",
                      device: device,
                    })
              }}
            >
              <IonIcon icon={trashOutline} />
            </IonButton>
          </Item>
        ))}
    </>
  )

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Devices</IonCardTitle>
        </IonCardHeader>
        <IonCardContent style={{ height: 315, overflowY: "auto" }}>
          {!devices ? (
            <div className="container">
              <IonSpinner />
            </div>
          ) : (
            <IonList>{content}</IonList>
          )}
        </IonCardContent>
      </IonCard>
      {modal && (
        <InputDialog
          isOpen={modal}
          title={modal.title}
          placeholder={modal.placeholder}
          actionText={modal.actionText}
          dismissed={modal.dismissed}
        />
      )}
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => setAlert(undefined)}
          header={alert.title}
          message={alert.message}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Delete",
              cssClass: "destructive",
              handler: () => deleteDevice(alert.device),
            },
          ]}
        />
      )}
    </>
  )
}

export default Devices
