import React, { useState } from "react"
import { TextPanelType, TextPanelItem, PanelSize } from "../../data/PanelTypes"
import ItemsPanel, { ItemsPanelItem } from "./support/ItemsPanel"
import AddToTextPanel from "./support/AddToTextPanel"
import { IonPopover, IonAlert } from "@ionic/react"
import { panelsCol, deleteFieldValue } from "../../data/FirebaseRefs"
import showToast from "../showToast"
import Item from "../Item"
import ModalPopover from "../ModalPopover"

type TextPanelProps = {
  panel: TextPanelType
  size: PanelSize
}

const copyToClipboard = (text: string) => {
  var dummy = document.createElement("textarea")
  document.body.appendChild(dummy)
  dummy.innerHTML = text.replace("\n", "<br/>")
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

const TextPanel = ({ panel, size }: TextPanelProps) => {
  const [editPopover, setEditPopover] = useState<any>(undefined)
  const [alert, setAlert] = useState<any>(undefined)

  const convertToItems = () =>
    Object.keys(panel.items).map((key) => {
      let item = panel.items[key]
      return {
        id: key,
        date: item.date,
        title: item.text,
        subtitle: makeSubtitle(item),
      } as ItemsPanelItem
    })

  const makeSubtitle = (item: TextPanelItem) =>
    `${item.title !== "" ? item.title : ""}${
      item.subtitle !== ""
        ? item.title !== ""
          ? ` | ${item.subtitle}`
          : item.subtitle
        : ""
    }`

  const addDialog = (dismiss: Function, item?: TextPanelItem, id?: string) => (
    <AddToTextPanel panel={panel} item={item} dismiss={dismiss} id={id} />
  )

  const onClick = (item: ItemsPanelItem) => {
    const tmpItem = panel.items[item.id]
    if (isValidURL(tmpItem.title) && tmpItem.subtitle === "Webpage")
      window.open(tmpItem.title, "_blank")
    else copyItemToClipboard(item)
  }

  const copyItemToClipboard = (item: ItemsPanelItem) => {
    const tmpItem = panel.items[item.id]
    if (tmpItem.subtitle === "Webpage") copyToClipboard(tmpItem.title)
    else {
      var str = ""
      if (item.title !== "") str = tmpItem.title + "&#13;&#10;"
      if (item.subtitle !== "") str += tmpItem.subtitle + "&#13;&#10;"
      str += tmpItem.text
      copyToClipboard(str)
    }
    showToast("Copied to clipboard")
  }

  const deleteItem = (item: ItemsPanelItem) => {
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${item.id}`]: deleteFieldValue(),
      })
      .then(() => showToast(`"${item.title}" deleted`))
      .catch(() => showToast(`Couldn't delete "${item.title}"`, "error"))
  }

  const isValidURL = (text: string) => {
    try {
      new URL(text)
    } catch (_) {
      return false
    }
    return true
  }

  const menu = (
    item: ItemsPanelItem,
    isOpen: boolean,
    event: any,
    dismiss: Function
  ) => (
    <IonPopover isOpen={isOpen} event={event} onDidDismiss={() => dismiss()}>
      <Item
        button
        onClick={() => {
          setEditPopover({ id: item.id, item: panel.items[item.id] })
          dismiss()
        }}
      >
        Edit
      </Item>
      <Item
        button
        onClick={() => {
          copyItemToClipboard(item)
          dismiss()
        }}
      >
        Copy
      </Item>
      {isValidURL(panel.items[item.id].title) &&
        panel.items[item.id].subtitle === "Webpage" && (
          <Item
            button
            onClick={() => {
              window.open(panel.items[item.id].title, "_blank")
              dismiss()
            }}
          >
            Open Website
          </Item>
        )}
      <Item
        button
        onClick={() => {
          setAlert({
            title: "Delete Text?",
            message: "Confirm deletion of text.",
            item: item,
          })
          dismiss()
        }}
      >
        Delete
      </Item>
    </IonPopover>
  )

  const actions = {
    addDialog,
    onClick,
    menu,
  }

  return (
    <>
      <div style={size === "fullScreen" ? { height: "100%" } : {}}>
        <ItemsPanel
          metadata={panel.metadata}
          items={convertToItems()}
          actions={actions}
          size={size}
        />
      </div>
      {editPopover && (
        <ModalPopover
          isOpen={editPopover}
          cssClass="add-popover"
          title="Edit Item"
          onDidDismiss={() => setEditPopover(undefined)}
        >
          {addDialog(
            () => setEditPopover(undefined),
            editPopover.item,
            editPopover.id
          )}
        </ModalPopover>
      )}
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => setAlert(undefined)}
          header={alert.title}
          message={alert.message}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Delete",
              cssClass: "destructive",
              handler: () => deleteItem(alert.item),
            },
          ]}
        />
      )}
    </>
  )
}

export default TextPanel
