import { useState, useEffect } from "react"
import { auth } from "firebase"

const useIsSignedIn = () => {
  const [isSignedIn, setIsSignedIn] = useState<null | boolean>(null)

  useEffect(() => {
    const removeListener = auth().onAuthStateChanged((fbUser) =>
      setIsSignedIn(!!fbUser)
    )
    return () => removeListener()
  }, [])

  return isSignedIn
}

export default useIsSignedIn
