import React, { useEffect } from "react"
import { Redirect, Route } from "react-router-dom"
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/react"
import { IonReactRouter, IonReactHashRouter } from "@ionic/react-router"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"

import useIsSignedIn from "./hooks/useIsSignedIn"
import Home from "./pages/Home"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import NoMatch from "./pages/NoMatch"
import Main from "./pages/Main"
import SignIn from "./pages/SignIn"
import { FirebaseContextProvider } from "./FirebaseContext"
import Downloads from "./pages/Downloads"
import useCurrentDevice from "./hooks/useCurrentDevice"
import { Storage } from "@capacitor/core"

const Router: any = isPlatform("electron") ? IonReactHashRouter : IonReactRouter

const App: React.FC = () => {
  const isSignedIn = useIsSignedIn()
  const currentDevice = useCurrentDevice()

  const isPWA = () =>
    window.matchMedia("(display-mode: standalone)").matches ||
    document.referrer.includes("android-app://") ||
    currentDevice.platform !== "web" ||
    isPlatform("electron")

  useEffect(() => {
    if (isSignedIn !== null && !isSignedIn)
      Storage.set({ key: "shouldAskDeviceName", value: "true" })
  }, [isSignedIn])

  return (
    <IonApp>
      {isSignedIn !== null && (
        <Router>
          <IonRouterOutlet>
            <Route path="/user">
              {isSignedIn ? (
                <FirebaseContextProvider>
                  <Main />
                </FirebaseContextProvider>
              ) : (
                <Redirect to={isPWA() ? "/login" : "/"} />
              )}
            </Route>
            <Route exact path="/login">
              {isSignedIn ? <Redirect to="/user" /> : <SignIn />}
            </Route>
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/downloads" component={Downloads} />
            <Route exact path="/">
              {isSignedIn || isPWA() ? <Redirect to="/user" /> : <Home />}
            </Route>
            <Route component={NoMatch} />
          </IonRouterOutlet>
        </Router>
      )}
    </IonApp>
  )
}

export default App
