import React, { useState } from "react"
import { FilesPanelType, PanelSize } from "../../data/PanelTypes"
import ItemsPanel, { ItemsPanelItem } from "./support/ItemsPanel"
import { panelsCol, deleteFieldValue } from "../../data/FirebaseRefs"
import { IonPopover, IonAlert } from "@ionic/react"
import showToast from "../showToast"
import AddToFilesPanel from "./support/AddToFilesPanel"
import Item from "../Item"
import { functions } from "firebase"
import InputDialog from "../InputDialog"
import ModalPopover from "../ModalPopover"

type FilesPanelProps = {
  panel: FilesPanelType
  size: PanelSize
}

const startDownload = (url: string, name: string) => {
  var dummy = document.createElement("a")
  dummy.download = name
  dummy.href = url
  document.body.appendChild(dummy)
  dummy.click()
  document.body.removeChild(dummy)
}

const FilesPanel = ({ panel, size }: FilesPanelProps) => {
  const [alert, setAlert] = useState<any>(undefined)
  const [renameDialog, setRenameDialog] = useState<any>(undefined)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [showAddPopover, setShowAddPopover] = useState(false)

  const convertToItems = () =>
    Object.keys(panel.items)
      .map((key) => {
        let item = panel.items[key]
        if (item.status === 2)
          return {
            id: key,
            date: item.date,
            title: item.name,
            subtitle: formatBytes(item.size),
          }
        else return undefined
      })
      .filter((item) => item !== undefined) as ItemsPanelItem[]

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
  }

  const addDialog = (dismiss: Function, file?: File) => (
    <AddToFilesPanel
      panel={panel}
      dismiss={dismiss}
      formatBytes={formatBytes}
      withFile={file}
    />
  )

  const onClick = (item: ItemsPanelItem) => {
    showToast("Starting download...")
    functions()
      .httpsCallable("getFileDownloadURL")({
        panelId: panel.id,
        itemId: item.id,
        name: item.title,
      })
      .then((res) => {
        if (res.data !== "ERROR") startDownload(res.data, item.title)
        else showToast(`Couldn't download ${item.title}`, "error")
      })
      .catch((error) => showToast(`Couldn't download ${item.title}`, "error"))
  }

  const deleteItem = (item: ItemsPanelItem) => {
    panelsCol()
      .doc(panel.id)
      .update({
        [`items.${item.id}`]: deleteFieldValue(),
      })
      .then(() => showToast(`"${item.title}" deleted`))
      .catch(() => showToast(`Couldn't delete "${item.title}"`, "error"))
  }

  // const withoutExtension = (str: string) => str.replace(/\.[^/.]+$/, "")
  // const onlyExtention = (str: string) => (/[.]/.exec(str)) && (/[^.]+$/.exec(str)) ? /[^.]+$/.exec(str)![0] : undefined

  const renameItem = (item: ItemsPanelItem, newName?: string) => {
    setRenameDialog(undefined)
    if (!newName) return
    if (newName === item.title) return
    showToast(`Renaming ${item.title} to ${newName}...`)
    functions()
      .httpsCallable("renameFile")({
        panelId: panel.id,
        itemId: item.id,
        currentName: item.title,
        newName,
      })
      .then((res) => {
        if (res.data !== "ERROR")
          showToast(`Renamed ${item.title} to ${newName} successfully`)
        else showToast(`Couldn't rename ${item.title}`, "error")
      })
      .catch((error) => showToast(`Couldn't rename ${item.title}`, "error"))
  }

  const menu = (
    item: ItemsPanelItem,
    isOpen: boolean,
    event: any,
    dismiss: Function
  ) => (
    <IonPopover isOpen={isOpen} event={event} onDidDismiss={() => dismiss()}>
      <Item
        button
        onClick={() => {
          onClick(item)
          dismiss()
        }}
      >
        Download
      </Item>
      <Item
        button
        onClick={() => {
          setRenameDialog({
            item: item,
          })
          dismiss()
        }}
      >
        Rename
      </Item>
      <Item
        button
        onClick={() => {
          setAlert({
            title: `Delete ${item.title}?`,
            message: `Confirm deletion of ${item.title}.`,
            item: item,
          })
          dismiss()
        }}
      >
        Delete
      </Item>
    </IonPopover>
  )

  const actions = {
    addDialog,
    onClick,
    menu,
  }

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (
      Array.from(document.getElementsByTagName("ion-title"))
        .map((elem) => elem.innerText)
        .includes("Upload File")
    )
      return
    setFile(event.dataTransfer.files[0])
    setShowAddPopover(true)
  }

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  return (
    <>
      <div
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        style={size === "fullScreen" ? { height: "100%" } : {}}
      >
        <ItemsPanel
          metadata={panel.metadata}
          items={convertToItems()}
          actions={actions}
          size={size}
        />
      </div>
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => setAlert(undefined)}
          header={alert.title}
          message={alert.message}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Delete",
              cssClass: "destructive",
              handler: () => deleteItem(alert.item),
            },
          ]}
        />
      )}
      {file && (
        <ModalPopover
          isOpen={showAddPopover}
          cssClass="add-popover"
          title="Upload File"
          onDidDismiss={() => setShowAddPopover(false)}
        >
          {actions.addDialog(() => setShowAddPopover(false), file)}
        </ModalPopover>
      )}
      {renameDialog && (
        <InputDialog
          isOpen={!!renameDialog}
          title={`Rename ${renameDialog.item.title}`}
          defaultValue={renameDialog.item.title}
          placeholder="New Name"
          actionText="Rename"
          dismissed={(text?: string) => renameItem(renameDialog.item, text)}
        />
      )}
    </>
  )
}

export default FilesPanel
