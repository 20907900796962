import React, { useEffect } from "react"
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  isPlatform,
} from "@ionic/react"
import SendyToolbarButtons from "./SendyToolbarButtons"

type IonNavProps = {
  title: string
  browserTitle?: string
  showToolbarButtons: boolean
  children: JSX.Element | JSX.Element[]
}

const IonNavPage = (props: IonNavProps) => {
  useEffect(() => {
    if (isPlatform("electron")) {
      document.title = "Sendy"
      return
    }
    const prev = document.title
    if (props.browserTitle) document.title = props.browserTitle
    else if (props.title === "Sendy")
      document.title = "Sendy - Connect your devices"
    else document.title = `Sendy - ${props.title}`
    return () => {
      document.title = prev
    }
  }, [props.title, props.browserTitle])

  return (
    <IonPage>
      <IonHeader style={{ "-webkit-app-region": "drag" }}>
        <IonToolbar>
          <IonButtons
            slot="start"
            style={{ marginLeft: isPlatform("electron") ? 70 : 0 }}
          >
            {props.title === "Sendy" && (
              <IonMenuButton
                style={{ "-webkit-app-region": "no-drag", marginRight: -15, marginLeft: isPlatform("electron") ? 10 : 0 }}
              />
            )}
          </IonButtons>
          <IonTitle style={{ WebkitUserSelect: "none" }}>
            {props.title}
          </IonTitle>
          {props.showToolbarButtons && <SendyToolbarButtons />}
        </IonToolbar>
      </IonHeader>
      <IonContent>{props.children}</IonContent>
    </IonPage>
  )
}

IonNavPage.defaultProps = {
  showToolbarButtons: true,
}

export default IonNavPage
