import React, { useRef } from "react"
import {
  IonPopover,
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonButtons,
  IonTitle,
  isPlatform,
} from "@ionic/react"
import useScreenSize from "../hooks/useScreenSize"

const ModalPopover = (props: any) => {
  const { width } = useScreenSize()
  const modal = useRef<any>(null)

  const item = (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle
            style={{
              marginLeft: width < 640 && isPlatform("electron") ? 70 : 0,
            }}
          >
            {props.title}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current.dismiss()} color="primary">
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>{props.children}</IonContent>
    </>
  )

  return width >= 640 ? (
    <IonPopover {...props} ref={modal}>
      {item}
    </IonPopover>
  ) : (
    <IonModal {...props} ref={modal} swipeToClose={true}>
      {item}
    </IonModal>
  )
}

export default ModalPopover
