import { auth, firestore, storage } from "firebase"

/** Returns the current user `uid`. */
export const userID = () => auth().currentUser?.uid ?? "default"

/** Returns the current user `uid`. */
export const userEmail = () => auth().currentUser?.email ?? "default"

/* Returns the current user reference in Firestore. */
export const userRef = () =>
  firestore()
    .collection("users")
    .doc(auth().currentUser?.uid ?? "default")

/** Returns the root panels reference in Firestore. */
export const panelsCol = () => firestore().collection("panels")

/** Returns a query that selects all of the current user's panels in Firestore. */
export const panelsQuery = () =>
  panelsCol().where("metadata.shared", "array-contains", userID())

/** Returns a reference to Firebase storage. */
export const storageRef = () => storage().ref().child("uploads").child(userID())

/** Returns the current date in Timestamp. */
export const currentTimestamp = () => firestore.Timestamp.now()

/** Returns the current date in Timestamp. */
export const deleteFieldValue = () => firestore.FieldValue.delete()
