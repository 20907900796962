import React, { useState, useContext } from "react"
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonAlert,
  IonSpinner,
  IonList,
  IonListHeader,
  IonLabel,
  IonButton,
  IonIcon,
} from "@ionic/react"
import "../../theme/styles.css"
import usePanelsData from "../../hooks/usePanelsData"
import Item from "../Item"
import {
  panelTypes,
  PanelType,
  createEmptyPanel,
  PanelMetadataType,
} from "../../data/PanelTypes"
import { trashOutline } from "ionicons/icons"
import { panelsCol } from "../../data/FirebaseRefs"
import showToast from "../showToast"
import randomString from "../../data/randomString"
import InputDialog from "../InputDialog"
import { FirebaseContextType, FirebaseContext } from "../../FirebaseContext"

const EditPanels = () => {
  const { user } = useContext<FirebaseContextType>(FirebaseContext)
  const { isLoading, panels, panelIds } = usePanelsData()
  const [modal, setModal] = useState<any>(undefined)
  const [alert, setAlert] = useState<any>(undefined)

  const deletePanel = (panel: PanelType) => {
    panelsCol()
      .doc(panel.id)
      .delete()
      .then(() => showToast(`Deleted ${panel.metadata.name} successfully`))
      .catch((error) => showToast(error, "error"))
  }

  const showEditPanelPopover = (panel: PanelType) => {
    const finishEditPanel = (text?: string) => {
      setModal(undefined)
      if (!text) return
      panelsCol()
        .doc(panel.id)
        .update({ "metadata.name": text })
        .then(() => showToast("Updated Panel successfully"))
        .catch((error) => showToast(error.toString(), "error"))
    }
    setModal({
      title: "Edit Panel",
      placeholder: "New Name",
      actionText: "Save",
      dismissed: finishEditPanel,
      defaultValue: panel.metadata.name,
    })
  }

  const showAddPanelPopover = (type: PanelMetadataType) => {
    const finishAddPanel = (text?: string) => {
      setModal(undefined)
      if (!text || !user || !panelIds) return
      if (!user.isSubscribed()) {
        if (panelIds.length >= 3 ?? false) {
          showToast(
            "Sendy Free users can create up to 3 Panels. Upgrade to Sendy+ to be able to add unlimited Panels.",
            "warning"
          )
          return
        }
      }
      panelsCol()
        .doc(randomString(20))
        .set(createEmptyPanel(type, text))
        .then(() => showToast("Added Panel successfully"))
        .catch((error) => showToast(error.toString(), "error"))
    }
    setModal({
      title: "Add Panel",
      placeholder: "Name",
      actionText: "Create Panel",
      dismissed: finishAddPanel,
    })
  }

  const removeTags = (s: string) =>
    s.replace(/</gi, "&lt;").replace(/>/gi, "&gt;")

  const editPanels = (
    <>
      {panelIds && panelIds?.length > 0 && (
        <IonListHeader>
          <IonLabel color="medium" style={{ fontSize: 18 }}>
            <b>Edit Panels</b>
          </IonLabel>
        </IonListHeader>
      )}
      {panelIds
        ?.map((id) => {
          if (panels === null) return undefined
          const panel = panels[id]
          return panel !== undefined ? (
            <Item button key={id} onClick={() => showEditPanelPopover(panel)}>
              <IonLabel>{panel.metadata.name}</IonLabel>
              <IonButton
                fill="clear"
                shape="round"
                className="destructive"
                onClick={(e) => {
                  e.stopPropagation()
                  e.persist()
                  setAlert({
                    title: `Delete ${panel.metadata.name}?`,
                    message: `Confirm deletion of ${removeTags(
                      panel.metadata.name
                    )}.`,
                    panel: panel,
                  })
                }}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            </Item>
          ) : undefined
        })
        .filter((item) => item !== undefined)}
    </>
  )

  const capitalize = (s: string) =>
    typeof s !== "string" ? "" : s.charAt(0).toUpperCase() + s.slice(1)

  const addPanels = (
    <>
      <IonListHeader>
        <IonLabel color="medium" style={{ fontSize: 18 }}>
          <b>Add Panels</b>
        </IonLabel>
      </IonListHeader>
      {panelTypes.map((type) => (
        <Item button key={type} onClick={() => showAddPanelPopover(type)}>
          <IonLabel>{capitalize(type)} Panel</IonLabel>
        </Item>
      ))}
    </>
  )

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Panels</IonCardTitle>
        </IonCardHeader>
        <IonCardContent style={{ height: 315, overflowY: "auto" }}>
          {isLoading ? (
            <div className="container">
              <IonSpinner />
            </div>
          ) : (
            <IonList>
              {editPanels}
              {addPanels}
            </IonList>
          )}
        </IonCardContent>
      </IonCard>
      {modal && (
        <InputDialog
          isOpen={modal}
          title={modal.title}
          placeholder={modal.placeholder}
          actionText={modal.actionText}
          dismissed={modal.dismissed}
          defaultValue={modal.defaultValue}
        />
      )}
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => setAlert(undefined)}
          header={alert.title}
          message={alert.message}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Delete",
              cssClass: "destructive",
              handler: () => deletePanel(alert.panel),
            },
          ]}
        />
      )}
    </>
  )
}

export default EditPanels
