import React, { useState } from "react"
import { IonButton, IonSpinner, IonLabel, IonAlert } from "@ionic/react"

const ChangePaddlePlan = ({ subscriptionHandler, subscriptionData }: any) => {
  const [alert, setAlert] = useState<any>(undefined)
  const [loadingMonthlyButton, setLoadingMonthlyButton] = useState(false)
  const [loadingYearlyButton, setLoadingYearlyButton] = useState(false)

  const changePaddlePlan = (newPlan: string) => {
    if (subscriptionData.status === "trialing") {
      setAlert({
        title: "You Can't Change Plan Yet",
        message: "You'll be able to change your plan after your trial ends.",
      })
    } else {
      const newPlanPrice =
        newPlan === "monthly"
          ? subscriptionHandler.monthlyPrice
          : subscriptionHandler.yearlyPrice
      setAlert({
        title: "Confirm Plan Change",
        message: `Do you want to move to the ${newPlan} plan for ${newPlanPrice}?`,
        buttonText: "Confirm",
        action: () => planChangeConfirmed(newPlan),
      })
    }
  }

  const planChangeConfirmed = async (newPlan: string) => {
    const setLoadingButton =
      newPlan === "monthly" ? setLoadingMonthlyButton : setLoadingYearlyButton
    setLoadingButton(true)
    const res = (await subscriptionHandler.changePaddlePlan(newPlan)).data
    setLoadingButton(false)
    if (!res.success) {
      setAlert({
        title: "Couldn't Change Plan",
        message: `${res.error.message}<br/>Error code: ${res.error.code}`,
      })
    }
  }

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <IonButton
          fill="outline"
          expand="block"
          style={{ height: 50 }}
          disabled={subscriptionData.plan === "monthly"}
          onClick={() => changePaddlePlan("monthly")}
        >
          {subscriptionHandler.monthlyPrice && !loadingMonthlyButton ? (
            <IonLabel>
              {subscriptionHandler.monthlyPrice} Monthly
              {subscriptionData.plan === "monthly" && (
                <>
                  <br />
                  Current Plan
                </>
              )}
            </IonLabel>
          ) : (
            <IonSpinner />
          )}
        </IonButton>
        <IonButton
          fill="outline"
          expand="block"
          style={{ height: 50 }}
          disabled={subscriptionData.plan === "yearly"}
          onClick={() => changePaddlePlan("yearly")}
        >
          {subscriptionHandler.yearlyPrice && !loadingYearlyButton ? (
            <IonLabel>
              {subscriptionHandler.yearlyPrice} Yearly
              {subscriptionData.plan === "yearly" && (
                <>
                  <br />
                  Current Plan
                </>
              )}
            </IonLabel>
          ) : (
            <IonSpinner />
          )}
        </IonButton>
      </div>
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => {
            setAlert(undefined)
            setLoadingMonthlyButton(false)
            setLoadingYearlyButton(false)
          }}
          header={alert.title}
          message={alert.message}
          buttons={
            alert.buttonText
              ? [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: alert.buttonText,
                    cssClass: "destructive",
                    handler: alert.action,
                  },
                ]
              : [
                  {
                    text: "Cancel",
                    role: "cancel",
                  },
                ]
          }
        />
      )}
    </>
  )
}

export default ChangePaddlePlan
