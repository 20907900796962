import React from "react"
import IonNavPage from "../components/IonNavPage"
import { IonButton } from "@ionic/react"
import "../theme/styles.css"

const NoMatch: React.FC = () => (
  <IonNavPage title="Sendy" browserTitle="Sendy - Not Found">
    <div className="container">
      <strong>404 Not Found</strong>
      <br />
      <IonButton routerLink="/" fill="outline">
        Go to the Home Page
      </IonButton>
    </div>
  </IonNavPage>
)

export default NoMatch
