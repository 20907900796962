import React, { useEffect, useState } from "react"
import { firestore, auth } from "firebase"
import User, { ServerUser, UserType } from "./data/User"
import useCurrentDevice from "./hooks/useCurrentDevice"
import useIsSignedIn from "./hooks/useIsSignedIn"
import { userRef } from "./data/FirebaseRefs"
import { Plugins } from "@capacitor/core"
const FirebaseContext = React.createContext<any>({})

type FirebaseContextProviderProps = {
  children: JSX.Element
}

type User = {
  accout: Account
  devices: { string: Device }
}

type Account = {
  expire: firestore.Timestamp
  pro: boolean
}

type Device = {
  fcm: string
  name: string
}

export type FirebaseContextType = {
  user?: UserType
  isSignedIn: boolean | null
  currentDevice: {
    platform: "web" | "ios" | "android" | "electron"
    id: () => Promise<string>
    name: () => Promise<string>
    fcm: () => Promise<string>
    setName: Function
    setFcm: Function
    getInfo: () => Promise<{
      id: string
      name: string
      fcm: string
    }>
  }
  deleteDeviceCompeletely: Function
  updateDevice: Function
}

/* Used to stop `userRef` listener. */
let unsubscribe: Function | null = null

const FirebaseContextProvider = (props: FirebaseContextProviderProps) => {
  const isSignedIn = useIsSignedIn()
  const [user, setUser] = useState<undefined | UserType>(undefined)
  const currentDevice = useCurrentDevice()

  useEffect(() => {
    if (isSignedIn === true) updateUserData()
    else if (!auth().currentUser && unsubscribe) unsubscribe()
    // eslint-disable-next-line
  }, [isSignedIn])

  /**
  Syncs basic user data from Firestore.
    + `updateUserData` gets all of the user's devices from Firestore and updates the current device information like its name and FCM.
    + `updateUserData` detects if the current device was marked as deleted in Firestore and signs out if necessary.
  */
  async function updateUserData() {
    if (unsubscribe) unsubscribe()
    userRef()
      .get({ source: "server" })
      .then((snapshot) => completeUserRefRequest(snapshot, true))
  }

  /**
  Updates a device in Firestore.

  @param id: Device's id
  @param data: Device as object
  */
  const updateDevice = (id: string, data: any) =>
    userRef().update({ [`devices.${id}`]: data })

  /**
  Deletes a device from Firestore.

  @param id: Device's id
  */
  const deleteDeviceCompeletely = (id: string) => {
    setUser(undefined)
    if (unsubscribe) unsubscribe()
    Plugins.Storage.clear()
    return userRef().update({
      [`devices.${id}`]: firestore.FieldValue.delete(),
    })
  }

  async function completeUserRefRequest(
    snapshot: firestore.DocumentSnapshot,
    shouldStartListener: boolean = false
  ) {
    if (!auth().currentUser?.metadata.creationTime) return
    const creationTime = new Date(auth().currentUser!.metadata.creationTime!)
    const diff = (new Date().getTime() - creationTime.getTime()) / 1000
    if (!snapshot.exists && diff > 10) {
      const WINDOW = window as any
      WINDOW.$crisp.push(["do", "session:reset"])
      auth().signOut()
      return
    }
    if (!snapshot.data()) return
    const tmpUser = User(snapshot.data() as ServerUser)
    // find the current device in Firestore
    const deviceId = await currentDevice.id()
    const device = tmpUser.devices().find((device) => device.id === deviceId)
    if (device) {
      // if the current device was marked as deleted, delete
      // it from Firestore and sign out
      if (device.fcm === "deleted") {
        deleteDeviceCompeletely(device.id).then(() => {
          const WINDOW = window as any
          WINDOW.$crisp.push(["do", "session:reset"])
          auth().signOut()
        })
      } else {
        if (shouldStartListener) {
          unsubscribe = userRef().onSnapshot((snapshot) =>
            completeUserRefRequest(snapshot)
          )
        } else {
          setUser(tmpUser)
          auth().currentUser?.getIdTokenResult(true)
          // update current device name if changed
          await currentDevice.setName(device.name)
          updateDevice(device.id, {
            name: device.name,
            fcm: await currentDevice.fcm(),
          })
        }
      }
    } else {
      // upload the current device to Firestore if it wasn't uploaded yet
      const device = await currentDevice.getInfo()
      updateDevice(device.id, {
        name: device.name,
        fcm: device.fcm,
      }).then(() => updateUserData())
    }
  }

  const value = {
    user,
    isSignedIn,
    currentDevice,
    deleteDeviceCompeletely,
    updateDevice,
  }

  return (
    <FirebaseContext.Provider value={value}>
      {props.children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseContextProvider, FirebaseContext }
