import React, { useState, useRef, useEffect, FormEvent } from "react"
import ModalPopover from "./ModalPopover"
import Item from "./Item"
import { IonInput, IonButton } from "@ionic/react"
import showToast from "./showToast"

type PasswordInputDialogProps = {
  isOpen: boolean
  title: string
  placeholder: string
  actionText: string
  dismissed: Function
  type?:
    | "number"
    | "time"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "search"
    | "date"
    | "password"
}

const PasswordInputDialog = (props: PasswordInputDialogProps) => {
  const [password, setPassword] = useState("")
  const [text, setText] = useState("")
  const input = useRef<any>(null)

  useEffect(() => {
    setTimeout(() => input.current.setFocus(), 100)
  }, [])

  const finishEditText = (e: FormEvent) => {
    e.preventDefault()
    if (text === "" || password === "") {
      showToast("You didn't type anything.", "error")
      return
    }
    const currentPassword = password
    const newText = text
    setPassword("")
    setText("")
    props.dismissed(newText, currentPassword)
  }

  return (
    <ModalPopover
      isOpen={props.isOpen}
      cssClass="add-popover"
      title={props.title}
      onDidDismiss={() => props.dismissed()}
    >
      <div style={{ padding: 8 }}>
        <form onSubmit={finishEditText}>
          <Item className="item-radius" style={{ marginTop: 8 }}>
            <IonInput
              ref={input}
              placeholder="Current Password"
              type="password"
              value={password}
              onIonChange={(e) => setPassword(e.detail.value ?? "")}
            />
          </Item>
          <Item className="item-radius" style={{ marginTop: 8 }}>
            <IonInput
              placeholder={props.placeholder}
              type={props.type ?? "text"}
              value={text}
              onIonChange={(e) => setText(e.detail.value ?? "")}
            />
          </Item>
          <IonButton
            type="submit"
            expand="block"
            style={{ marginTop: 8, height: 45 }}
          >
            {props.actionText}
          </IonButton>
        </form>
      </div>
    </ModalPopover>
  )
}

export default PasswordInputDialog
