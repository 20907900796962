import { useState, useEffect } from "react"
import { Plugins } from "@capacitor/core"
const { Storage, Device } = Plugins

export default function useCurrentDevice() {
  const [platform, setPlatform] = useState<
    "web" | "ios" | "android" | "electron"
  >("web")

  const id = async () => (await Device.getInfo()).uuid
  const name = async () =>
    (await Storage.get({ key: "deviceName" })).value ?? "Your Computer"
  const fcm = async () => (await Storage.get({ key: "deviceFcm" })).value ?? ""

  const setName = (name: string) =>
    Storage.set({ key: "deviceName", value: name })
  const setFcm = (fcm: string) => Storage.set({ key: "deviceFcm", value: fcm })

  const getInfo = async () => ({
    id: await id(),
    name: await name(),
    fcm: await fcm(),
  })

  useEffect(() => {
    Device.getInfo().then((info) => setPlatform(info.platform))
  }, [])

  return {
    platform,
    id,
    name,
    fcm,
    setName,
    setFcm,
    getInfo,
  }
}
