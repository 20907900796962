import React from "react"
import IonNavPage from "../components/IonNavPage"
import "../theme/styles.css"

const PrivacyPolicy: React.FC = () => (
  <IonNavPage title="Privacy Policy" showToolbarButtons={false}>
    <div className="container">
      <strong>Privacy Policy</strong>
      <p>Will be available when the app is live.</p>
    </div>
  </IonNavPage>
)

export default PrivacyPolicy
