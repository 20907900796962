import React from "react"
import { IonLabel } from "@ionic/react"

const SubscriptionTable = () => (
  <div
    style={{
      padding: 8,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      height: 245,
      borderRadius: 12,
      border: "2px solid grey",
      marginBottom: 4,
    }}
  >
    <style
      dangerouslySetInnerHTML={{
        __html: `
          .ul-free { list-style: none; padding: 0; margin: 0 0 3rem; } .ul-free li { padding-left: 1rem; text-indent: -0.7rem; } .ul-free li::before { content: "✅ "; }
          .ul-plus { list-style: none; padding: 0; margin: 0 0 3rem; } .ul-plus li { padding-left: 1rem; text-indent: -0.7rem; } .ul-plus li::before { content: "🚀 "; }
        `,
      }}
    ></style>
    <div>
      <img
        src="./landing/static/img/twemoji/1f423.svg"
        height={30}
        alt="Sendy Free icon"
      />
      <IonLabel>
        <h1 style={{ fontWeight: 500, fontSize: 18 }}>Sendy Free</h1>
        <p>Perfect for beginners</p>
      </IonLabel>
      <ul
        className="ul-free"
        style={{ textAlign: "left", fontSize: 14, marginTop: 8 }}
      >
        <li>3 Panels</li>
        <li>3 items in each Panel</li>
        <li>Max 10MB file size</li>
        <li>Ad supported</li>
        <li>Basic support</li>
      </ul>
    </div>
    <div>
      <img
        src="./landing/static/img/twemoji/1f451.svg"
        height={30}
        alt="Sendy+ icons"
      />
      <IonLabel>
        <h1 style={{ fontWeight: 500, fontSize: 18 }}>Sendy+</h1>
        <p>Ultimate flexability</p>
      </IonLabel>
      <ul
        className="ul-plus"
        style={{ textAlign: "left", fontSize: 14, marginTop: 8 }}
      >
        <li>Unlimited Panels</li>
        <li>100 items in each Panel</li>
        <li>Max 1GB file size</li>
        <li>Universal clipboard</li>
        <li>Ad free</li>
        <li>Premium support</li>
      </ul>
    </div>
  </div>
)

export default SubscriptionTable
