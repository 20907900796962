import React, { useState, useEffect } from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import IonNavPage from "../components/IonNavPage"
import { auth } from "firebase"
import ModalPopover from "../components/ModalPopover"
import { Plugins } from "@capacitor/core"
import { setTimeout } from "timers"
import {
  IonIcon,
  IonButton,
  isPlatform,
  IonSpinner,
  useIonRouter,
} from "@ionic/react"
import "../theme/styles.css"
import { compass } from "ionicons/icons"
import Bowser from "bowser"

type SignInProps = {
  isPopover?: boolean
  redirect?: string
  popoverProps?: any
  onSignIn?: Function
  style?: any
}

const SignIn = ({
  isPopover = false,
  redirect,
  popoverProps,
  onSignIn,
}: SignInProps) => {
  const [signInFlow, setSignInFlow] = useState<"popup" | "redirect">("popup")
  const [render, setRender] = useState(false)
  const { push } = useIonRouter()

  useEffect(() => {
    Plugins.Device.getInfo().then((info) =>
      setSignInFlow(info.platform === "web" ? "popup" : "redirect")
    )
  }, [])

  useEffect(() => {
    if (render) return
    setTimeout(() => {
      setRender(true)
    }, 500)
  }, [render])

  const uiConfig = {
    signInFlow: signInFlow,
    credentialHelper: "none",
    privacyPolicyUrl: "https://sendy.app/privacy-policy",
    tosUrl: "https://sendy.app/privacy-policy",
    signInOptions: [
      {
        provider: auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
      {
        provider: "apple.com",
      },
      auth.TwitterAuthProvider.PROVIDER_ID,
      auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (_authResult: any, _redirectURL: any) => {
        isPlatform("electron")
          ? window.location.reload()
          : push(redirect ?? "/user")
        return false
      },
    },
  }

  const deleteUiConfig = {
    signInFlow: signInFlow,
    credentialHelper: "none",
    privacyPolicyUrl: "https://sendy.app/privacy-policy",
    tosUrl: "https://sendy.app/privacy-policy",
    signInOptions: [
      {
        provider: auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (_authResult: any, _redirectURL: any) => {
        onSignIn!()
        return false
      },
    },
  }

  const signInView = (
    <StyledFirebaseAuth
      uiConfig={onSignIn ? deleteUiConfig : uiConfig}
      firebaseAuth={auth()}
    />
  )

  const safariWarning = (
    <div style={{ textAlign: "center", margin: 8, marginTop: 45 }}>
      <IonIcon icon={compass} size="large" />
      <h3>Safari is Not Supported</h3>
      <p>Please switch to a different browser or download the Sendy app.</p>
      <IonButton fill="clear" onClick={() => (window as any).downloadClient()}>
        Download for {(window as any).getOS()}
      </IonButton>
    </div>
  )

  const content = render ? (
    Bowser.getParser(window.navigator.userAgent).getBrowserName() ===
    "Safari" ? (
      safariWarning
    ) : (
      signInView
    )
  ) : (
    <div style={{ textAlign: "center" }}>
      <IonSpinner style={{ marginTop: 125 }} />
    </div>
  )

  return !isPopover ? (
    <IonNavPage title="Sign In">{content}</IonNavPage>
  ) : (
    <ModalPopover {...popoverProps} title="Sign In">
      {content}
    </ModalPopover>
  )
}

export default SignIn
