import React, { useEffect, useState } from "react"
import { Subscription } from "../../data/User"
import { IonLabel } from "@ionic/react"
import useSubscriptionHandler from "../../hooks/useSubscriptionHandler"
import BuySubscription from "./support/BuySubscription"
import SubscriptionTable from "./support/SubscriptionTable"
import ManagePaddleSubscription from "./support/ManagePaddleSubscription"
import ChangePaddlePlan from "./support/ChangePaddlePlan"
import Confetti from "react-confetti"

type SubscriptionProps = {
  subscription?: Subscription
  isSubscribed: boolean
}

const SubscriptionModal = ({
  subscription,
  isSubscribed,
}: SubscriptionProps) => {
  const subscriptionHandler = useSubscriptionHandler()
  const [platform, setPlatform] = useState("")

  const [showConfetti, setShowConfetti] = useState(false)
  const [numberOfPieces, setNumberOfPieces] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    subscriptionHandler.platform().then((plat) => setPlatform(plat))
  }, [subscriptionHandler])

  useEffect(() => {
    if (showConfetti) setTimeout(() => setNumberOfPieces(0), 5000)
  }, [showConfetti])

  const manageInStore = () =>
    subscription?.provider === "paddle" ? (
      <IonLabel>
        <h2 style={{ fontWeight: 500 }}>
          Manage your subscription in Sendy website
          <br />
          <a href="https://sendy.app">sendy.app</a>
        </h2>
      </IonLabel>
    ) : (
      <IonLabel>
        <h2 style={{ fontWeight: 500 }}>
          Manage your subscription in the{" "}
          {subscription?.provider === "apple" ? "App" : "Play"} Store
        </h2>
      </IonLabel>
    )

  return subscription && isSubscribed && subscription.status !== "deleted" ? (
    <div style={{ padding: 8, textAlign: "center" }}>
      {/* eslint-disable-next-line */}
      <IonLabel>You're subscribed to Sendy+ ❤️</IonLabel>
      {(platform === "web" || platform === "electron") &&
      subscription.provider === "paddle" ? (
        <>
          <IonLabel>
            <p>Change your plan</p>
          </IonLabel>
          <ChangePaddlePlan
            subscriptionHandler={subscriptionHandler}
            subscriptionData={subscription}
          />
          <IonLabel>
            <p>Update your subscription</p>
          </IonLabel>
          <ManagePaddleSubscription
            subscriptionHandler={subscriptionHandler}
            subscriptionData={subscription}
          />
        </>
      ) : (
        <>
          {manageInStore()}
          <SubscriptionTable />
        </>
      )}
      <Confetti run={showConfetti} numberOfPieces={numberOfPieces} />
    </div>
  ) : (
    <div style={{ padding: 8, textAlign: "center" }}>
      <SubscriptionTable />
      <IonLabel>
        {subscription ? (
          <p>Resubscribe to Sendy+</p>
        ) : (
          <p>All Sendy+ plans include one month free trial</p>
        )}
      </IonLabel>
      <BuySubscription
        subscriptionHandler={subscriptionHandler}
        setShowConfetti={setShowConfetti}
      />
      <IonLabel>
        {/* eslint-disable-next-line */}
        <p>With the yearly plan you get 2 free months 🎁</p>
      </IonLabel>
    </div>
  )
}

export default SubscriptionModal
