import React, { useState, useEffect, useContext } from "react"
import usePanelsData from "../../hooks/usePanelsData"
import "../../theme/styles.css"
import { IonSpinner, IonSplitPane } from "@ionic/react"
import { FirebaseContextType, FirebaseContext } from "../../FirebaseContext"
import showToast from "../showToast"
import InputDialog from "../InputDialog"
import { Storage } from "@capacitor/core"
import PanelsContent from "./PanelsContent"
import PanelsMenu from "./PanelsMenu"

const Panels = () => {
  const { isLoading, panels, panelIds } = usePanelsData()
  const [selectedPanel, setSelectedPanel] = useState("all")
  const [emptyIcon, setEmptyIcon] = useState(setIcon())
  const [showNameDevicePopover, setShowNameDevicePopover] = useState(false)
  const fbContext = useContext<FirebaseContextType>(FirebaseContext)

  useEffect(() => {
    window.matchMedia("(prefers-color-scheme: dark)").addListener(() => {
      setEmptyIcon(setIcon())
    })
  }, [])

  function setIcon() {
    return document.body.classList.contains("dark") ? "white" : "black"
  }

  useEffect(() => {
    if (isLoading) return
    Storage.get({ key: "shouldAskDeviceName" }).then((obj) => {
      if (obj.value === "true") {
        setShowNameDevicePopover(true)
        setTimeout(() => {
          Storage.remove({ key: "shouldAskDeviceName" })
        }, 300)
      }
    })
  }, [isLoading])

  const finishEditName = (text?: string) => {
    setShowNameDevicePopover(false)
    if (!text) return
    fbContext.currentDevice.getInfo().then((device) => {
      fbContext
        .updateDevice(device.id, {
          name: text,
          fcm: device.fcm,
        })
        .catch((error: any) => showToast(error, "error"))
    })
  }

  return (
    <>
      {isLoading ? (
        <div className="container">
          <IonSpinner />
        </div>
      ) : panels && panelIds && panelIds?.length > 0 ? (
        <IonSplitPane
          contentId="panels"
          className="split-pane"
          when="(min-width: 1030px)"
        >
          <PanelsMenu
            panelIds={panelIds}
            panels={panels}
            selectedPanel={selectedPanel}
            setSelectedPanel={(id: string) => setSelectedPanel(id)}
          />
          <PanelsContent
            panelIds={panelIds}
            panels={panels}
            selectedPanel={selectedPanel}
          />
        </IonSplitPane>
      ) : (
        <div className="container">
          <img src={`/assets/icon/${emptyIcon}-icon.png`} width="35" alt="" />
          <br />
          <strong>No Panels</strong>
          <p>Tap on the settings icon in the top right corner to add Panels.</p>
        </div>
      )}
      {showNameDevicePopover ? (
        <InputDialog
          isOpen={showNameDevicePopover}
          title="Name This Device"
          placeholder="New Name"
          actionText="Rename"
          canBeEmpty={true}
          dismissed={finishEditName}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Panels
