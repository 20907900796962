import { Plugins } from "@capacitor/core"
import { useState, useEffect, useContext } from "react"
import { auth, functions } from "firebase"
import { Purchases } from "@ionic-native/purchases"
import { FirebaseContext, FirebaseContextType } from "../FirebaseContext"

const useSubscriptionHandler = () => {
  const { user } = useContext<FirebaseContextType>(FirebaseContext)

  const platform = async () => (await Plugins.Device.getInfo()).platform
  const Paddle = (window as any).Paddle

  const [monthlyPrice, setMonthlyPrice] = useState<string | undefined>(
    undefined
  )
  const [yearlyPrice, setYearlyPrice] = useState<string | undefined>(undefined)

  useEffect(() => {
    calculateMonthlyPrice()
    calculateYearlyPrice()
    // eslint-disable-next-line
  }, [])

  async function calculateMonthlyPrice() {
    switch (await platform()) {
      case "android":
        Purchases.getOfferings().then((offers) => {
          const pkg = offers.current?.availablePackages.find(
            (pkg) => pkg.identifier === "Monthly"
          )
          setMonthlyPrice(pkg?.product.price_string)
        })
        break
      default:
        Paddle.Product.Prices(593763, (prices: any) => {
          setMonthlyPrice(prices.recurring.price.gross)
        })
    }
  }

  async function calculateYearlyPrice() {
    switch (await platform()) {
      case "android":
        Purchases.getOfferings().then((offers) => {
          const pkg = offers.current?.availablePackages.find(
            (pkg) => pkg.identifier === "Yearly"
          )
          setMonthlyPrice(pkg?.product.price_string)
        })
        break
      default:
        Paddle.Product.Prices(593764, (prices: any) => {
          setYearlyPrice(prices.recurring.price.gross)
        })
    }
  }

  async function buyMonthlyPlan(
    showConfetti: Function,
    hideLoadingPurchaseIndicator: Function
  ) {
    switch (await platform()) {
      case "android":
        const plan = await getRevenueCatPlan("monthly")
        hideLoadingPurchaseIndicator()
        if (!plan) return
        Purchases.purchasePackage(plan)
          .then(({ productIdentifier, purchaserInfo }) => showConfetti())
          .catch(({ error, userCancelled }) =>
            console.log("Couldn't purchase package: " + error)
          )
        break
      default:
        const planID = await getPaddlePlanID("monthly")
        hideLoadingPurchaseIndicator()
        Paddle.Checkout.open({
          product: planID,
          email: auth().currentUser?.email,
          passthrough: JSON.stringify({ userId: auth().currentUser?.uid }),
          disableLogout: true,
          successCallback: (_data: any) => {
            showConfetti()
          },
        })
    }
  }

  async function buyYearlyPlan(
    showConfetti: Function,
    hideLoadingPurchaseIndicator: Function
  ) {
    switch (await platform()) {
      case "android":
        const plan = await getRevenueCatPlan("yearly")
        hideLoadingPurchaseIndicator()
        if (!plan) return
        Purchases.purchasePackage(plan)
          .then(({ productIdentifier, purchaserInfo }) => showConfetti())
          .catch(({ error, userCancelled }) =>
            console.log("Couldn't purchase package: " + error)
          )
        break
      default:
        const planID = await getPaddlePlanID("yearly")
        hideLoadingPurchaseIndicator()
        Paddle.Checkout.open({
          product: planID,
          email: auth().currentUser?.email,
          passthrough: JSON.stringify({ userId: auth().currentUser?.uid }),
          disableLogout: true,
          successCallback: (_data: any) => {
            showConfetti()
          },
        })
    }
  }

  async function updatePaddleSubscription(updateURL: string) {
    Paddle.Checkout.open({ override: updateURL })
  }

  async function changePaddlePlan(newPlan: "monthly" | "yearly") {
    return functions().httpsCallable("changePaddlePlan")({ newPlan })
  }

  async function getPaddlePlanID(planType: "monthly" | "yearly") {
    return functions()
      .httpsCallable("getPaddlePlanID")({ planType })
      .then((res) => res.data as number)
  }

  async function getRevenueCatPlan(planType: "monthly" | "yearly") {
    if (!user) return
    const packages = (await Purchases.getOfferings()).current?.availablePackages
    if (!packages) return
    switch (planType) {
      case "monthly":
        return user.subscription
          ? packages.find((pkg) => pkg.identifier === "Monthly_Existing")
          : packages.find((pkg) => pkg.identifier === "Monthly")
      case "yearly":
        return user.subscription
          ? packages.find((pkg) => pkg.identifier === "Yearly_Existing")
          : packages.find((pkg) => pkg.identifier === "Yearly")
    }
  }

  return {
    platform,
    monthlyPrice,
    yearlyPrice,
    buyMonthlyPlan,
    buyYearlyPlan,
    updatePaddleSubscription,
    changePaddlePlan,
  }
}

export default useSubscriptionHandler
