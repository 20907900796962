import React, { useContext, useState, useEffect } from "react"
import "../../theme/styles.css"
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonAlert,
} from "@ionic/react"
import { FirebaseContext, FirebaseContextType } from "../../FirebaseContext"
import { auth } from "firebase"
import Item from "../Item"
import showToast from "../showToast"
import PasswordInputDialog from "../PasswordInputDialog"
import ModalPopover from "../ModalPopover"
import SubscriptionModal from "./Subscription"
import formatDate from "../../data/formatDate"
import { useLocation } from "react-router"
import { Plugins } from "@capacitor/core"
import SignIn from "../../pages/SignIn"

const Account: React.FC = () => {
  const { hash } = useLocation()
  const [signInFlow, setSignInFlow] = useState<"popup" | "redirect">("popup")
  const fbContext = useContext<FirebaseContextType>(FirebaseContext)
  const [dialog, setDialog] = useState<any>(undefined)
  const [alert, setAlert] = useState<any>(undefined)
  const [showSubscription, setShowSubscription] = useState(false)
  const [showEmailLogin, setShowEmailLogin] = useState(false)
  const [currentEmail, setCurrentEmail] = useState(
    auth().currentUser?.email ?? ""
  )

  useEffect(() => {
    Plugins.Device.getInfo().then((info) =>
      setSignInFlow(info.platform === "web" ? "popup" : "redirect")
    )
    auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user) deleteAccount()
      })
  }, [])

  useEffect(() => {
    setShowSubscription(hash.includes("#subscription"))
  }, [hash])

  const provider =
    auth().currentUser?.providerData[0]?.providerId ??
    auth.EmailAuthProvider.PROVIDER_ID

  const signedInWithString = () => {
    switch (provider) {
      case auth.EmailAuthProvider.PROVIDER_ID:
        return "Signed in with Email"
      case auth.GoogleAuthProvider.PROVIDER_ID:
        return "Signed in with Google"
      case auth.TwitterAuthProvider.PROVIDER_ID:
        return "Signed in with Twitter"
      default:
        return "Signed in with Apple"
    }
  }

  const signOut = () => {
    fbContext.currentDevice.id().then((id) => {
      fbContext.deleteDeviceCompeletely(id).then(() => {
        ;(window as any).$crisp.push(["do", "session:reset"])
        auth().signOut()
      })
    })
  }

  const signOutAlert = () => {
    setAlert({
      title: "Sign Out",
      message: "Are you sure?",
      buttonText: "Sign Out",
      action: signOut,
    })
  }

  const changeEmail = () => {
    const finishChangeEmail = (text?: string, currentPassword?: string) => {
      setDialog(undefined)
      if (!text || !currentPassword) return
      auth()
        .signInWithEmailAndPassword(currentEmail, currentPassword)
        .then(() => {
          auth()
            .currentUser?.updateEmail(text)
            .then(() => {
              showToast(`Changed email to ${text}`)
              setCurrentEmail(auth().currentUser?.email ?? "")
            })
            .catch((error) => showToast(error.toString(), "error"))
        })
        .catch((error) =>
          showToast(
            error.code === "auth/wrong-password"
              ? "The password is incorrect."
              : error.toString(),
            "error"
          )
        )
    }
    setDialog({
      title: "Change Email",
      placeholder: "New Email",
      actionText: "Change Email",
      dismissed: finishChangeEmail,
      type: "email",
    })
  }

  const changePassword = () => {
    const finishChangePassword = (text?: string, currentPassword?: string) => {
      setDialog(undefined)
      if (!text || !currentPassword) return
      auth()
        .signInWithEmailAndPassword(currentEmail, currentPassword)
        .then(() => {
          auth()
            .currentUser?.updatePassword(text)
            .then(() => showToast("Password updated successfully"))
            .catch((error) => showToast(error.toString(), "error"))
        })
        .catch((error) =>
          showToast(
            error.code === "auth/wrong-password"
              ? "The password is incorrect."
              : error.toString(),
            "error"
          )
        )
    }
    setDialog({
      title: "Change Password",
      placeholder: "New Password",
      actionText: "Change Password",
      dismissed: finishChangePassword,
      type: "password",
    })
  }

  const reauthenticate = () => {
    const providerId = auth().currentUser?.providerData[0]?.providerId
    if (providerId === auth.EmailAuthProvider.PROVIDER_ID) {
      setShowEmailLogin(true)
    } else {
      let provider: auth.AuthProvider
      switch (providerId) {
        case auth.TwitterAuthProvider.PROVIDER_ID:
          provider = new auth.TwitterAuthProvider()
          break
        case auth.GoogleAuthProvider.PROVIDER_ID:
          provider = new auth.GoogleAuthProvider()
          break
        default:
          provider = new auth.OAuthProvider("apple.com")
          break
      }
      if (signInFlow === "popup") {
        auth()
          .signInWithPopup(provider)
          .then((res) => deleteAccount())
          .catch((error) => {
            if (
              error.code !== "auth/cancelled-popup-request" &&
              error.code !== "auth/popup-closed-by-user"
            ) {
              showToast(error.toString(), "error")
            }
          })
      } else {
        auth().signInWithRedirect(provider)
      }
    }
  }

  const deleteAccount = () => {
    setShowEmailLogin(false)
    auth()
      .currentUser?.delete()
      .then(() => showToast("Your account has been deleted."))
      .catch((error) => showToast(error.toString(), "error"))
  }

  const deleteAccountAlert = () => {
    const { user } = fbContext
    if (!user) return
    if (user.isSubscribed()) {
      setAlert({
        title: "Please Wait Until Your Subscription is Over",
        message:
          "You'll be able to delete your account after your subscription has been ended. Please cancel it if you didn't already and wait until your current subscription ends.",
      })
      return
    }
    fbContext.currentDevice.platform === "android"
      ? setAlert({
          title: "Can't Delete Account On Android",
          message:
            "To delete your account, open Sendy on your web browser or on iOS.",
        })
      : setAlert({
          title: "Delete Account",
          message:
            "All your data will be deleted.<br/>You'll need to sign in again to confirm your identity.",
          buttonText: "Delete",
          action: () => {
            reauthenticate()
            setAlert(undefined)
          },
        })
  }

  const subscriptionItem = () => {
    const subscription = fbContext.user?.subscription
    return (
      <Item button onClick={() => setShowSubscription(true)}>
        {subscription && fbContext.user?.isSubscribed() ? (
          <IonLabel>
            Your subscription is active
            <p>
              {subscription.endDate &&
                `Cancellation date: ${formatDate(
                  subscription.endDate.toDate()
                )}`}
              {subscription.nextBillDate &&
                `Next due date: ${formatDate(
                  subscription.nextBillDate.toDate()
                )}`}
            </p>
          </IonLabel>
        ) : (
          // eslint-disable-next-line
          <IonLabel>
            🚀Upgrade to Sendy+
            {!subscription && <p>Start your one month free trial</p>}
          </IonLabel>
        )}
      </Item>
    )
  }

  const emailItem = () => {
    if (provider === auth.EmailAuthProvider.PROVIDER_ID) {
      return (
        <Item button onClick={changeEmail}>
          <IonLabel>
            Change Email
            {currentEmail && <p>{currentEmail}</p>}
          </IonLabel>
        </Item>
      )
    } else {
      return (
        <Item>
          <IonLabel>
            {signedInWithString()}
            {currentEmail && <p>{currentEmail}</p>}
          </IonLabel>
        </Item>
      )
    }
  }

  const passwordItem = () => {
    return (
      <Item button onClick={changePassword}>
        <IonLabel>Change Password</IonLabel>
      </Item>
    )
  }

  const signOutItem = () => (
    <Item button onClick={signOutAlert}>
      Sign Out
    </Item>
  )

  const deleteAccountItem = () => (
    <Item button onClick={deleteAccountAlert}>
      Delete Account
    </Item>
  )

  const content = (
    <>
      {subscriptionItem()}
      {emailItem()}
      {provider === auth.EmailAuthProvider.PROVIDER_ID && passwordItem()}
      {signOutItem()}
      {deleteAccountItem()}
    </>
  )

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Account</IonCardTitle>
        </IonCardHeader>
        <IonCardContent style={{ height: 315, overflowY: "auto" }}>
          {content}
        </IonCardContent>
      </IonCard>
      <ModalPopover
        isOpen={showSubscription}
        cssClass="add-popover"
        title={
          fbContext.user?.isSubscribed() &&
          fbContext.user.subscription?.status !== "deleted"
            ? "Your Subscription"
            : "Upgrade to Sendy+"
        }
        onDidDismiss={() => {
          setShowSubscription(false)
        }}
      >
        <SubscriptionModal
          subscription={fbContext.user?.subscription}
          isSubscribed={fbContext.user?.isSubscribed() ?? false}
        />
      </ModalPopover>
      {dialog && (
        <PasswordInputDialog
          isOpen={dialog}
          title={dialog.title}
          placeholder={dialog.placeholder}
          actionText={dialog.actionText}
          dismissed={dialog.dismissed}
          type={dialog.type}
        />
      )}
      {alert && (
        <IonAlert
          isOpen={!!alert}
          onDidDismiss={() => setAlert(undefined)}
          header={alert.title}
          message={alert.message}
          buttons={
            alert.action
              ? [
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: alert.buttonText,
                    cssClass: "destructive",
                    handler: alert.action,
                  },
                ]
              : [
                  {
                    text: "OK",
                    role: "cancel",
                  },
                ]
          }
        />
      )}
      <SignIn
        isPopover={true}
        onSignIn={deleteAccount}
        popoverProps={{
          isOpen: showEmailLogin,
          cssClass: "login-popover",
          onDidDismiss: () => setShowEmailLogin(false),
        }}
      />
    </>
  )
}

export default Account
