import { useState, useEffect } from "react"
import { firestore } from "firebase"
import { panelsQuery } from "../data/FirebaseRefs"
import { PanelType } from "../data/PanelTypes"

export type Panels = {
  [id: string]: PanelType
}

let panelIds: string[] | null = null

const usePanelsData = () => {
  const [panels, setPanels] = useState<Panels | null>(null)

  useEffect(() => {
    const unsubscribe = panelsQuery().onSnapshot((snapshot) =>
      gotQueryResult(snapshot)
    )
    return () => unsubscribe()
    // eslint-disable-next-line
  }, [])

  function gotQueryResult(snapshot: firestore.QuerySnapshot) {
    let panelsData: { [id: string]: any } = {}
    snapshot.docs.forEach(
      (doc) => (panelsData[`${doc.id}`] = { ...doc.data(), id: doc.id })
    )
    makeIds(panelsData)
    setPanels(panelsData)
  }

  function makeIds(from: Panels) {
    const ids = Object.values(from)
      .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name))
      .map((panel) => panel.id)
    panelIds = ids
  }

  return {
    panels,
    panelIds,
    isLoading: !panels,
  }
}

export default usePanelsData
